import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import AirborneIndexDetails from "./AirborneIndexDetails";
import CarbonEmissionsDetails from "./CarbonEmissionsDetails";
import ComfortIndexDetails from "./ComfortIndexDetails";
import EnergyCostDetails from "./EnergyCostDetails";
import EnergyUsageDetails from "./EnergyUsageDetails";
import EnergyUseIntensityDetails from "./EnergyUseIntensityDetails";
import HealthScoreDetails from "./HealthScoreDetails";
import MouldIndexDetails from "./MouldIndexDetails";
import ProductivityIndexDetails from "./ProductivityIndexDetails";

export enum OverviewTileMetric {
  ComfortIndex = "COMFORT_INDEX",
  ProductivityIndex = "PRODUCTIVITY_INDEX",
  AirborneIndex = "AIRBORNE_INDEX",
  HealthScore = "HEALTH_SCORE",
  MouldIndex = "MOULD_INDEX",
  CarbonEmissions = "CARBON_EMISSIONS",
  EnergyUseIntensity = "ENERGY_USE_INTENSITY",
  OccupancyIndex = "OCCUPANCY_INDEX",
  EnergyUsage = "ENERGY_USAGE",
  EnergyCost = "ENERGY_COST",
}

interface OverviewTileDetailPopupProps {
  propertyId: string;
  metric: OverviewTileMetric;
  onClose: () => void;
  isOpen: boolean;
  dateRange: TimestampRange;
}

const OverviewTileDetailPopup = ({
  propertyId,
  metric,
  onClose,
  isOpen,
  dateRange,
}: OverviewTileDetailPopupProps) => {
  return (
    <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <>
          <ModalBody p={0}>
            {metric === OverviewTileMetric.ComfortIndex && (
              <ComfortIndexDetails propertyId={propertyId} dateRange={dateRange} />
            )}
            {metric === OverviewTileMetric.ProductivityIndex && (
              <ProductivityIndexDetails propertyId={propertyId} dateRange={dateRange} />
            )}
            {metric === OverviewTileMetric.AirborneIndex && (
              <AirborneIndexDetails propertyId={propertyId} dateRange={dateRange} />
            )}
            {metric === OverviewTileMetric.HealthScore && (
              <HealthScoreDetails propertyId={propertyId} dateRange={dateRange} />
            )}
            {metric === OverviewTileMetric.MouldIndex && (
              <MouldIndexDetails propertyId={propertyId} dateRange={dateRange} />
            )}
            {metric === OverviewTileMetric.CarbonEmissions && (
              <CarbonEmissionsDetails propertyId={propertyId} dateRange={dateRange} />
            )}
            {metric === OverviewTileMetric.EnergyUseIntensity && (
              <EnergyUseIntensityDetails propertyId={propertyId} dateRange={dateRange} />
            )}
            {metric === OverviewTileMetric.EnergyUsage && (
              <EnergyUsageDetails propertyId={propertyId} dateRange={dateRange} />
            )}
            {metric === OverviewTileMetric.EnergyCost && (
              <EnergyCostDetails propertyId={propertyId} dateRange={dateRange} />
            )}
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  );
};

export default OverviewTileDetailPopup;
