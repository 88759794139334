/** Gaussian / Bankers rounding -- rounds to the nearest even number to avoid things like 33.5 and 66.5 adding up to 101 after rounding */
export function gaussianRound(x: number) {
    const absolute = Math.abs(x);
    const sign     = x == 0 ? 0 : (x < 0 ? -1 : 1);
    const floored  = Math.floor(absolute);
    if (absolute - floored != 0.5) {
        return Math.round(absolute) * sign;
    }
    if (floored % 2 == 1) {
        // Closest even is up.
        return Math.ceil(absolute) * sign;
    }
    // Closest even is down.
    return floored * sign;
}
