import { Box, chakra, ResponsiveValue, Skeleton, Tag, Text } from "@chakra-ui/react";
import { BsStarFill } from "react-icons/bs";
import { TbHeartOff } from "react-icons/tb";
import { AirborneRisk } from "../../../lib/api/types/InstallationLocationStatistics";
import { gaussianRound } from "../../../lib/utils/gaussianRound";
import AirborneIndexReportIcon from "../../icons/empty-state-metric-icons/AirborneIndexReportIcon";
import { HealthScore } from "../HealthScore";
import { getHealthScoreText } from "../overviewGridHelper";
import { OverviewTile } from "../OverviewTile";
import { OverviewTileV2 } from "../OverviewTileV2";
import { PercentageDelta } from "../PercentageDelta";
import { PercentageGraph } from "../PercentageGraph";
import { StatsPieChart } from "./StatsPieChart";
import { OverviewTileMetric } from "../detail-view/OverviewTileDetailPopup";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { AirborneIndexPercentages } from "../../../lib/api/types/Insights";
import { FaVirus } from "react-icons/fa";
import AirborneIndexChart from "../../airborne-index-chart";

const NO_DEVICE_STATE = {
  text: "Track the risk of airbourne viruses transmission with our ",
  icon: <FaVirus size={20} fill={"#7133C3"} />,
  unlockText: "an EnviroQ",
  title: "Airborne Index",
};

const NO_DATA_STATE = { icon: <FaVirus size={16} fill="lightGray" /> };

interface AirborneIndexTileProps {
  riskPercentages?: Record<AirborneRisk, number>;
  previousRiskPercentages?: Record<AirborneRisk, number>;
  hasEnviroQs: boolean;
  colSpan?: ResponsiveValue<number | "auto">;
  rowSpan?: ResponsiveValue<number | "auto">;
  isLoading: boolean;
  airborneIndexPercentages?: AirborneIndexPercentages;
  previousAirborneIndexPercentages?: AirborneIndexPercentages;
  propertyId: string;
  dateRange: TimestampRange;
  detailsMetricButton?: boolean;
}

export const getAirborneIndexStatusText = (
  highRisk?: number,
  midRisk?: number,
  previousHighRisk?: number,
  previousMidRisk?: number
) => {
  if (highRisk === undefined || midRisk === undefined) {
    return "";
  }

  const timeAtRisk = (gaussianRound(highRisk * 1000) + gaussianRound(midRisk * 1000)) / 10;
  const previousTimeAtRisk =
    previousHighRisk !== undefined && previousMidRisk !== undefined
      ? gaussianRound(previousHighRisk * 100) + gaussianRound(previousMidRisk * 100)
      : false;
  if (timeAtRisk === 0) {
    const subText = (
      <Text>
        <Text fontWeight="bold" display="inline">
          100%
        </Text>{" "}
        of time was spent at <b>low</b> risk &nbsp;
        {previousTimeAtRisk !== false && (
          <Box display="inline" sx={{ "*": { display: "inline-block !important" } }}>
            <PercentageDelta currentValue={timeAtRisk} previousValue={previousTimeAtRisk} />
          </Box>
        )}
      </Text>
    );
    return subText;
  } else {
    return (
      <Text>
        <Text fontWeight="bold" display="inline">
          {timeAtRisk}%
        </Text>{" "}
        of time was spent at a virus transmisson <b>risk</b> level &nbsp;
        {previousTimeAtRisk !== false && (
          <Box display="inline" sx={{ "*": { display: "inline-block !important" } }}>
            <PercentageDelta inverted currentValue={timeAtRisk} previousValue={previousTimeAtRisk} />
          </Box>
        )}
      </Text>
    );
  }
};

export const AirborneIndexTile = ({
  hasEnviroQs,
  colSpan,
  rowSpan,
  isLoading,
  airborneIndexPercentages,
  previousAirborneIndexPercentages,
  propertyId,
  dateRange,
  detailsMetricButton,
}: AirborneIndexTileProps) => {
  return (
    <OverviewTileV2
      reading={""}
      subText={
        airborneIndexPercentages !== undefined
          ? getAirborneIndexStatusText(
              airborneIndexPercentages?.high,
              airborneIndexPercentages?.mid,
              previousAirborneIndexPercentages?.high,
              previousAirborneIndexPercentages?.mid
            )
          : ""
      }
      showNoDeviceState={airborneIndexPercentages?.high == null && !hasEnviroQs}
      showNoDataState={airborneIndexPercentages?.high == null}
      noDeviceStateConfig={NO_DEVICE_STATE}
      noDataStateConfig={NO_DATA_STATE}
      isLoading={isLoading}
      colSpan={colSpan}
      rowSpan={rowSpan}
      heading="Airborne Index"
      toolTipText=""
      detailsMetric={OverviewTileMetric.AirborneIndex}
      propertyId={propertyId}
      dateRange={dateRange}
      detailsMetricButton={detailsMetricButton}
      iconBg={"rgba(113, 51, 195, 0.1)"}
    >
      {Boolean(!airborneIndexPercentages) && <Skeleton w={300} height={4} m={0} />}
      {airborneIndexPercentages != undefined && (
        <>
          <AirborneIndexChart type="radial" airborneIndexPercentages={airborneIndexPercentages} />
        </>
      )}
    </OverviewTileV2>
  );
};
