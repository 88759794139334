import { Box, Flex, Text } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { PieChart, PieChartProps } from "react-minimal-pie-chart";
import { BaseDataEntry, Data, LabelRenderFunction } from "react-minimal-pie-chart/types/commonTypes";
import { VictoryPie } from "victory-pie";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { gaussianRound } from "../../../lib/utils/gaussianRound";

interface StatsPieChartProps {
  graphData: Data<BaseDataEntry>;
  mode?: "proportion" | "percentage";
  proportionTotal?: number;
  size?: "sm" | "md";
  unit?: string;
}

export const StatsPieChart = ({
  graphData,
  mode = "percentage",
  proportionTotal,
  size = "md",
  unit = "",
}: StatsPieChartProps) => {
  const highchartsData = graphData.map((item) => ({
    y: mode === "percentage" ? gaussianRound(item.value * 1000) / 10 : item.value,
    color: item.color,
    name: item.title,
  }));

  const chartOptions = {
    chart: {
      type: "pie",
      marginTop: { sm: -285, md: -270 }[size],
      backgroundColor: "transparent",
    },
    title: {
      text: "",
      align: "center",
      verticalAlign: "middle",
      y: 60,
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ["50%", "50%"],
      },
    },
    tooltip: {
      valueSuffix: "%",
      useHTML: true,
      formatter: function (): string {
        //@ts-ignore
        return `${this.key} ${this.y}${mode === "percentage" ? "%" : ""}${unit}${
          mode === "proportion" && proportionTotal ? "/" + proportionTotal : ""
        }`;
      },
      style: { whiteSpace: "nowrap", textAlign: "center" },
    },
    series: [
      {
        innerSize: "60%",
        data: highchartsData,
        size: { sm: "85%", md: "95%" }[size],
        dataLabels: false,
        name: "",
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [{}],
          },
        },
      ],
    },
  };

  return (
    <Box
      height={{ sm: "100px", md: 115 }[size]}
      width={{ sm: 120, md: 140 }[size]}
      marginBottom={{ sm: 0, md: 2 }[size]}
      background="transparent"
      position="relative"
      overflow="hidden"
    >
      <HighchartsReact fill="transparent" highcharts={Highcharts} options={chartOptions} />
    </Box>
  );
};
