import { Flex, Text } from "@chakra-ui/react";
import { RoomInsights, PropertyInsights, MouldRiskPercentages } from "../../../lib/api/types/Insights";
import { gaussianRound } from "../../../lib/utils/gaussianRound";
import { InsightRisk, RiskColors } from "../types/types";
import HorizontalInsightsGraph from "./HorizontalInsightsGraph";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { startCase } from "lodash";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { Room } from "../../../lib/api/types/Room";
import { GiMushrooms } from "react-icons/gi";
import { getMouldRiskStatusText } from "../tiles/MouldIndexTile";
import MouldIndexChart from "../../mould-risk-chart/MouldRiskChart";

const sortRoomsByWorst = (a: RoomInsights, b: RoomInsights, rooms: Room[]) => {
  const {
    veryHigh: aVeryHigh = 0,
    high: aHigh = 0,
    mid: aMid = 0,
    low: aLow = 0,
  } = a.insights.mouldRiskPercentages;
  const {
    veryHigh: bVeryHigh = 0,
    high: bHigh = 0,
    mid: bMid = 0,
    low: bLow = 0,
  } = b.insights.mouldRiskPercentages;

  if (aVeryHigh > 0 || bVeryHigh > 0) {
    const diff = aVeryHigh - bVeryHigh;
    if (diff !== 0) {
      return diff;
    }
  } else if (aHigh > 0 || bHigh > 0) {
    const diff = aHigh - bHigh;
    if (diff !== 0) {
      return diff;
    }
  } else if (aMid > 0 || bMid > 0) {
    const diff = aMid - bMid;
    if (diff !== 0) {
      return diff;
    }
  } else if (aLow > 0 || bLow > 0) {
    const diff = aLow - bLow;
    if (diff !== 0) {
      return diff;
    }
  }

  const nameA = rooms.find((r) => r.id === a.roomId)?.name.toLowerCase() || 0;
  const nameB = rooms.find((r) => r.id === b.roomId)?.name.toLowerCase() || 0;
  return nameA > nameB ? 1 : nameA === nameB ? 0 : -1;
};

const renderRoomGraph = (roomInsights: RoomInsights) => {
  const { mouldRiskPercentages } = roomInsights.insights;

  if (mouldRiskPercentages === undefined) {
    return <Text>Unavailable for this room</Text>;
  }

  let atRiskPercentage = 0;

  const segments = Object.keys(mouldRiskPercentages).map((key) => {
    const riskLevel = startCase(key);
    const color = RiskColors[riskLevel as InsightRisk];
    const percentage = gaussianRound(100 * mouldRiskPercentages[key as keyof MouldRiskPercentages] || 0);

    atRiskPercentage += percentage;

    return {
      label: riskLevel,
      value: percentage,
      color: color || "",
      group: riskLevel === InsightRisk.Low ? "No risk" : "At risk",
    };
  });

  return (
    <Flex alignItems="center" ml={2}>
      <HorizontalInsightsGraph segments={segments} />
    </Flex>
  );
  // let risk = InsightRisk.Low;
  // if (mouldRiskPercentages.high > 0) {
  //   risk = InsightRisk.High;
  // } else if (mouldRiskPercentages.mid) {
  //   risk = InsightRisk.Mid;
  // }

  // return (
  //   <Flex>
  //     <Badge
  //       backgroundColor={darken(RiskColors[risk], -30)(theme)}
  //       fontSize="md"
  //       borderRadius="2xl"
  //       px={5}
  //       py={1}
  //       color={darken(RiskColors[risk], 10)(theme)}
  //     >
  //       {risk}
  //     </Badge>
  //   </Flex>
  // );
};

const MouldIndexExplainText = () => (
  <Text>
    Our Mould Index metric helps you monitor the risk for mould growth in your spaces.
    <br />
    <br />
    We use temperature, humidity, and time to track this risk. The longer that a building has high humidity
    levels and a suitable temperature, the greater the risk of mould growth becomes.
    <br />
    <br />
    Mould can cause a variety of health problems for the people who work or learn in these environments, such
    as respiratory issues, allergic reactions, and other health problems. Additionally, if mould levels get
    too high, it can cause damage to the building itself, which can be costly to fix.
    <br />
    <br />
    The Mould Index not only helps you understand and track the risk levels of your spaces, but it also has
    alerts to let you know when the risk levels increase, so you can proactively identify and address any
    issues that may be causing mould growth, such as water leaks or high humidity, before they get worse,
    keeping your spaces safe and healthy for everyone.
  </Text>
);

const renderOverviewGraph = (propertyInsights: PropertyInsights) => {
  const { mouldRiskPercentages } = propertyInsights.insights;

  if (!mouldRiskPercentages) {
    return <></>;
  }

  return (
    <Flex alignItems="center">
      <MouldIndexChart mouldRiskPercentages={mouldRiskPercentages} size="sm" type="radial" />
      {getMouldRiskStatusText(false, mouldRiskPercentages)}
    </Flex>
  );
};

const MouldIndexDetails = ({ propertyId, dateRange }: { propertyId: string; dateRange: TimestampRange }) => {
  return (
    <OverviewTileDetailView
      title="Mould Index"
      titleIcon={<GiMushrooms size={16} />}
      shortDescription="Our Mould Index metric helps you monitor the risk for mould growth in your spaces."
      longDescription={<MouldIndexExplainText />}
      showMoreCaption="Learn about our Mould Index"
      propertyId={propertyId}
      renderRoomGraph={renderRoomGraph}
      renderOverviewGraph={renderOverviewGraph}
      sortRoomsByWorst={sortRoomsByWorst}
      roomStatsTitle="Mould risk"
      filter={(insights: RoomInsights) => {
        return insights?.insights.mouldRiskPercentages !== undefined;
      }}
      dateRange={dateRange}
    />
  );
};

export default MouldIndexDetails;
