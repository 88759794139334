import { Flex, Text } from "@chakra-ui/react";
import { RoomInsights, AirborneIndexPercentages, PropertyInsights } from "../../../lib/api/types/Insights";
import { gaussianRound } from "../../../lib/utils/gaussianRound";
import { InsightRisk, RiskColors } from "../types/types";
import HorizontalInsightsGraph, { HorizontalGraphSegment } from "./HorizontalInsightsGraph";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { startCase } from "lodash";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { getAirborneIndexStatusText } from "../tiles/AirborneIndexTile";
import { FaVirus } from "react-icons/fa";
import AirborneIndexChart from "../../airborne-index-chart";

const renderRoomGraph = (roomInsights: RoomInsights) => {
  const { airborneIndexPercentages } = roomInsights.insights;

  if (airborneIndexPercentages === undefined) {
    return <Text>Unavailable for this room</Text>;
  }

  let atRiskPercentage = 0;

  const segments = Object.keys(airborneIndexPercentages).map((key) => {
    const riskLevel = startCase(key);
    const color = RiskColors[riskLevel as InsightRisk];
    const percentage = gaussianRound(
      100 * airborneIndexPercentages[key as keyof AirborneIndexPercentages] || 0
    );

    atRiskPercentage += percentage;

    return {
      label: riskLevel,
      value: percentage,
      color: color || "",
      group: "At risk",
    };
  });

  segments.push({
    label: InsightRisk.Low,
    value: 100 - atRiskPercentage,
    color: RiskColors[InsightRisk.Low],
    group: "No Risk",
  });

  const sortedByComfortOrder = Object.values(InsightRisk)
    .reverse()
    .map((key) => segments.find((s) => s.label === key))
    .filter((s) => s !== undefined) as HorizontalGraphSegment[];

  return (
    <Flex alignItems="center">
      <Text width={10} mr={2}>
        {gaussianRound(atRiskPercentage)}%
      </Text>
      <HorizontalInsightsGraph segments={sortedByComfortOrder} />
    </Flex>
  );
};

const renderOverviewGraph = (propertyInsights: PropertyInsights) => {
  const { airborneIndexPercentages } = propertyInsights.insights;

  if (!airborneIndexPercentages) {
    return <></>;
  }

  return (
    <Flex alignItems="center">
      <AirborneIndexChart airborneIndexPercentages={airborneIndexPercentages} size="sm" type="radial" />
      {getAirborneIndexStatusText(airborneIndexPercentages.high, airborneIndexPercentages.mid)}
    </Flex>
  );
};

const AirborneIndexExplainText = () => (
  <Text>
    Airborne Index is Tether’s CO2 monitoring solution to managing airborne virus transmission risk (airborne
    virus includes COVID, flu, pertussis (whooping cough) and RSV - respiratory syncytial virus.)
    <br />
    <br />
    Airborne Index tracks the saturation of Carbon Dioxide in your indoor air – that could be a classroom, a
    gym, an open plan office space or a restaurant.
    <br />
    <br />
    Tracking Carbon Dioxide levels demonstrates adequate or inadequate ventilation. In a populated area, if
    the space is not sufficiently ventilated then the air you breathe will have a higher percentage of exhaled
    breath from other occupants which may contain viral particles.
    <br />
    <br />
    The higher the CO2 levels, the higher chances of virus transmission. Airborne Index not only tracks your
    air quality, it also has alerts which notify you when risk levels are increasing, so you can take action.
    <br />
    <br />
    <Text mb={2} fontWeight="bold">
      Airborne Index
    </Text>
    <Text fontWeight="bold">Low risk</Text>
    CO2 levels below 800ppm <br />
    <Text mt={2} fontWeight="bold">
      Mid risk
    </Text>
    CO2 levels between 800-1250ppm <br />
    <Text mt={2} fontWeight="bold">
      High risk
    </Text>
    CO2 levels 1250ppm+
  </Text>
);

const AirborneIndexDetails = ({
  propertyId,
  dateRange,
}: {
  propertyId: string;
  dateRange: TimestampRange;
}) => {
  return (
    <OverviewTileDetailView
      title="Airborne Index"
      titleIcon={<FaVirus size={16} />}
      shortDescription="Airborne Index is Tether’s CO2 monitoring solution to managing airborne virus transmission risk (airborne virus includes COVID, flu, pertussis (whooping cough) and RSV - respiratory syncytial virus)."
      longDescription={<AirborneIndexExplainText />}
      showMoreCaption="Learn how to interpret our Airborne Index "
      propertyId={propertyId}
      renderRoomGraph={renderRoomGraph}
      renderOverviewGraph={renderOverviewGraph}
      sortRoomsByWorst={(a, b) =>
        b.insights.airborneIndexPercentages.mid +
        b.insights.airborneIndexPercentages.high -
        (a.insights.airborneIndexPercentages.mid + a.insights.airborneIndexPercentages.high)
      }
      roomStatsTitle="% of time at risk"
      filter={(insights: RoomInsights) => insights?.insights.airborneIndexPercentages !== undefined}
      dateRange={dateRange}
    />
  );
};

export default AirborneIndexDetails;
