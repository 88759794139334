import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { BoxProps, Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import MotionBox from "../motion/MotionBox";

interface PercentageDeltaProps extends BoxProps {
  /** Set true for increases to be highlighted red and decreases highlighted green */
  inverted?: boolean;
  currentValue: number;
  previousValue?: number;
  valueSuffix?: string;
  valuePrefix?: string | ReactNode;
  formatter?: (value: number) => string | ReactNode;
  fontSize?: string;
  fontColor?: string;
  hideText?: boolean;
  hideTooltip?: boolean;
  animationEnabled?: boolean;
  /** Set this to true if the values are percentages to use a direct subtraction instead of fractional change */
  valueIsPercentage?: boolean;
  changeIconTopMargin?: number;
}

export const PercentageDelta = ({
  currentValue,
  previousValue,
  valueSuffix,
  valuePrefix,
  formatter = (value) => value.toFixed(1),
  inverted,
  fontSize,
  fontColor,
  hideText,
  hideTooltip,
  animationEnabled = true,
  valueIsPercentage,
  changeIconTopMargin = 0,
  ...others
}: PercentageDeltaProps) => {
  const [isOpen, setIsOpen] = useState(false);
  if (previousValue === undefined) {
    return null;
  }

  let change = 0;
  if (valueIsPercentage) {
    change = Number((currentValue * 100 - previousValue * 100).toFixed(1));
  } else {
    change =
      previousValue === 0 ? 0 : Number((((currentValue - previousValue) * 100) / previousValue).toFixed(1));
  }

  let color = "green";
  if (change < 0 && !inverted) {
    color = "red";
  } else if (change > 0 && inverted) {
    color = "red";
  }

  const contents = (
    <>
      {change > 0 && (
        <Flex height="18px" {...others}>
          <TriangleUpIcon
            w={3}
            mr={1}
            color={color}
            height="18px"
            display="inline"
            mt={changeIconTopMargin}
          />
          {!hideText && (
            <Text as="span" color={fontColor || color} fontSize={fontSize} display="inline">
              {`${change < 0 ? "" : "+"}${change.toFixed(Math.abs(change) >= 10 ? 0 : 1)}`}%
            </Text>
          )}
        </Flex>
      )}
      {change < 0 && (
        <Flex height="18px" {...others}>
          <>
            <TriangleDownIcon
              w={3}
              mr={1}
              color={color}
              height="18px"
              display="inline"
              mt={changeIconTopMargin}
            />
          </>
          {!hideText && (
            <Text color={fontColor || color} fontSize={fontSize} display="inline">
              {`${change < 0 ? "" : "+"}${change.toFixed(Math.abs(change) >= 10 ? 0 : 1)}`}%
            </Text>
          )}
        </Flex>
      )}
    </>
  );

  return (
    <Tooltip
      label={
        previousValue != undefined &&
        !hideTooltip &&
        `Previous period: ${valuePrefix || ""}${formatter(previousValue)}${valueSuffix || ""}${
          valueIsPercentage ? "%" : ""
        }`
      }
      hasArrow={true}
      // This is manually controlled to allow avoiding a tooltip collision with the health score.
      isOpen={isOpen}
      placement="bottom"
    >
      <Flex
        onMouseEnter={(e) => {
          setIsOpen(true);
        }}
        onMouseLeave={() => {
          setIsOpen(false);
        }}
      >
        {animationEnabled && (
          <MotionBox transition={{ duration: 0.4 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {contents}
          </MotionBox>
        )}
        {!animationEnabled && contents}
      </Flex>
    </Tooltip>
  );
};
