import {
  ComfortIndexCategory,
  ComfortIndexCategoryMap,
  ComfortIndexPercentages,
} from "../../lib/api/types/Insights";
import { getComfortScoreColor, getComfortScoreDisplayText } from "../../lib/api/types/Room";
import LinearPercentageChart from "../linear-percentage-chart/LinearPercentageChart";
import { StatsPieChart } from "../overview-tiles/tiles/StatsPieChart";

interface ComfortScoreChartProps {
  type: "linear" | "radial";
  comfortIndexPercentages?: ComfortIndexPercentages;
  size?: "sm" | "md";
}

export const ComfortScoreChart = ({ type, comfortIndexPercentages, size }: ComfortScoreChartProps) => {
  const pieData = Object.keys(comfortIndexPercentages || {}).map((key) => {
    const comfortScore = ComfortIndexCategoryMap[key as ComfortIndexCategory];
    return {
      title: getComfortScoreDisplayText(comfortScore),
      key: key,
      value: comfortIndexPercentages
        ? (comfortIndexPercentages[key as keyof ComfortIndexPercentages] as number)
        : 0,
      color: getComfortScoreColor(comfortScore),
    };
  });

  // Find the index of the "perfectComfort" segment
  const perfectComfortIndex = pieData.findIndex((segment) => segment.key === "perfectComfort");

  // If "perfectComfort" exists, move it to the beginning of the pieData array
  if (perfectComfortIndex !== -1) {
    const perfectComfortSegment = pieData.splice(perfectComfortIndex, 1)[0];
    pieData.unshift(perfectComfortSegment);
  }

  switch (type) {
    case "linear":
      return <LinearPercentageChart sections={pieData.reverse()} />;
    case "radial":
    default:
      return <StatsPieChart graphData={pieData} size={size} />;
  }
};
