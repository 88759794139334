import { AirborneIndexPercentages } from "../lib/api/types/Insights";
import { AirborneRisk } from "../lib/api/types/InstallationLocationStatistics";
import LinearPercentageChart from "./linear-percentage-chart/LinearPercentageChart";
import { StatsPieChart } from "./overview-tiles/tiles/StatsPieChart";

export const getAirborneRiskColor = (risk: AirborneRisk) => {
  switch (risk) {
    case "low":
      return "#6DC96E";
    case "medium":
      return "#F2A63D";
    case "high":
      return "#FB5169";
  }

  return "gray";
};

export default function AirborneIndexChart({
  type,
  airborneIndexPercentages,
  size,
}: {
  type: "linear" | "radial";
  airborneIndexPercentages?: AirborneIndexPercentages;
  size?: "sm" | "md";
}) {
  const highMidTotal = (airborneIndexPercentages?.high || 0) + (airborneIndexPercentages?.mid || 0);
  const lowAirbornePercent = airborneIndexPercentages === undefined ? 0 : 1 - highMidTotal;

  const airbornePercentData = [
    { title: "Low Risk", value: lowAirbornePercent, color: "#6DC96E" },
    { title: "Mid Risk", value: airborneIndexPercentages?.mid || 0, color: "#F2A63D" },
    { title: "High Risk", value: airborneIndexPercentages?.high || 0, color: "#FB5169" },
  ];
  switch (type) {
    case "linear":
      return <LinearPercentageChart sections={airbornePercentData.reverse()} />;
    case "radial":
    default:
      return <StatsPieChart graphData={airbornePercentData} size={size} />;
  }
}
