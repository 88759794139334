import { Box, Select, Text } from "@chakra-ui/react";
import { useState } from "react";
import { BiDownArrow } from "react-icons/bi";
import AutoCompleteInput from "../../autocomplete-input/AutoCompleteInput";

interface AreaSortOption {
  label: string;
  value: AreaSortOptionKey;
  icon: JSX.Element;
}

export enum AreaSortOptionKey {
  Worst = "worst",
  Best = "best",
}

const defaultAreaSortOptions = [
  {
    label: "Worst areas",
    value: AreaSortOptionKey.Worst,
    icon: <BiDownArrow />,
  },
  {
    label: "Best areas",
    value: AreaSortOptionKey.Best,
    icon: <BiDownArrow />,
  },
];

const renderItem = (option: AreaSortOption) => (
  <Box>
    {option.icon}
    <Text>{option.label}</Text>
  </Box>
);

interface AreasSortDropdownProps {
  sortOptions?: AreaSortOption[];
  defaultOption?: AreaSortOption;
  onOptionSelected: (option: AreaSortOption) => void;
}

const AreasSortDropdown = ({
  sortOptions = defaultAreaSortOptions,
  defaultOption = defaultAreaSortOptions[0],
  onOptionSelected,
}: AreasSortDropdownProps) => {
  const [selected, setSelected] = useState<AreaSortOption>(defaultOption);

  return (
    <AutoCompleteInput
      width="250px"
      dropdownAsOverlay
      items={sortOptions}
      onChange={(value) => {
        const option = sortOptions.find((item) => item.value === value);

        if (option) {
          setSelected(option);
          onOptionSelected(option);
        }
      }}
      value={selected.value}
      renderSelectedItem={true}
      disableTextInput
      renderItem={renderItem}
    />
  );
};

export default AreasSortDropdown;
