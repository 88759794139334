import { Box, Text } from "@chakra-ui/layout";
import { ResponsiveValue } from "@chakra-ui/styled-system";
import React from "react";
import { FaBrain } from "react-icons/fa";
import { TbBrain } from "react-icons/tb";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { gaussianRound } from "../../../lib/utils/gaussianRound";
import BrainIcon from "../../icons/BrainIcon";
import ProductivityChart from "../../productivity-chart/ProductivityChart";
import { OverviewTileMetric } from "../detail-view/OverviewTileDetailPopup";
import { OverviewTileV2 } from "../OverviewTileV2";
import { PercentageDelta } from "../PercentageDelta";
import { StatsPieChart } from "./StatsPieChart";

interface ProductivityIndexTileProps {
  hasEnviroQs: boolean;
  colSpan?: ResponsiveValue<number | "auto">;
  rowSpan?: ResponsiveValue<number | "auto">;
  isLoading: boolean;
  productivityPercentage?: number;
  previousProductivityPercentage?: number;
  dateRange: TimestampRange;
  propertyId: string;
  detailsMetricButton?: boolean;
}

const NO_DEVICE_STATE = {
  text: "Track impact on congnitive function with our ",
  icon: <BrainIcon fill="#EA6BA6" size={20} />,
  unlockText: "an EnviroQ",
  title: "Focus Index",
};

const NO_DATA_STATE = {
  title: "Focus Index",
  text: "We are looking for your focus score but we can't find it.",
  icon: <FaBrain fill="lightGray" />,
};

export const ProductivityIndexStatusText = ({
  productivityPercentage,
  previousProductivityPercentage,
}: {
  productivityPercentage?: number;
  previousProductivityPercentage?: number;
}) => {
  if (!productivityPercentage === undefined) {
    return <></>;
  }

  const unproductiveLevel = 1 - (productivityPercentage || 0);
  const previousUnproductiveLevel = 1 - (previousProductivityPercentage || 0);

  return (
    <Text textAlign="center">
      <Text fontWeight="bold" display="inline">
        {gaussianRound(unproductiveLevel * 100)}%
      </Text>
      &nbsp;of time was spent at an <b>unproductive</b> level &nbsp;
      {previousProductivityPercentage !== undefined && (
        <Box display="inline" sx={{ "*": { display: "inline-block !important" } }}>
          <PercentageDelta
            inverted
            currentValue={unproductiveLevel * 100}
            previousValue={previousUnproductiveLevel * 100}
          />
        </Box>
      )}
    </Text>
  );
};

export const ProductivityIndexTile = ({
  hasEnviroQs,
  colSpan,
  rowSpan,
  isLoading,
  productivityPercentage,
  previousProductivityPercentage,
  dateRange,
  propertyId,
  detailsMetricButton,
}: ProductivityIndexTileProps) => {
  return (
    <>
      <OverviewTileV2
        reading={""}
        subText={""}
        showNoDeviceState={productivityPercentage === undefined && !hasEnviroQs}
        isLoading={isLoading}
        colSpan={colSpan}
        rowSpan={rowSpan}
        noDataStateConfig={NO_DATA_STATE}
        noDeviceStateConfig={NO_DEVICE_STATE}
        heading="Focus Index"
        showNoDataState={productivityPercentage === undefined}
        detailsMetric={OverviewTileMetric.ProductivityIndex}
        dateRange={dateRange}
        propertyId={propertyId}
        detailsMetricButton={detailsMetricButton}
        iconBg={"rgba(234, 107, 166, 0.1)"}
      >
        {/* {Boolean(!riskPercentages) && <Skeleton w={300} height={4} m={0} />} */}

        {productivityPercentage !== undefined && (
          <>
            <ProductivityChart productivityPercentage={productivityPercentage} type="radial" />
          </>
        )}
        <ProductivityIndexStatusText
          productivityPercentage={productivityPercentage}
          previousProductivityPercentage={previousProductivityPercentage}
        />
      </OverviewTileV2>
    </>
  );
};
