import { Box, Tooltip, VStack, HStack } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { CovidCo2RiskLevel } from "../../lib/utils/covid-care";
import { covidRiskColors } from "../covid-care/getCovidColorsAndRiskFromSample";
import HalfStarIcon from "../icons/HalfStarIcon";
import StarIcon from "../icons/StarIcon";

export const getHealthScoreColor = (healthScore: number) => {
  if (healthScore <= 3) {
    return covidRiskColors[CovidCo2RiskLevel.HIGH];
  }
  if (healthScore <= 6) {
    return covidRiskColors[CovidCo2RiskLevel.MODERATE];
  }

  return covidRiskColors[CovidCo2RiskLevel.LOW];
};

interface HealthScoreProps {
  healthScore?: number;
  previousHealthScore?: number;
  size?: "sm" | "md";
  wrap?: "wrap" | "nowrap";
  rowCount?: number;
  columnGap?: number | string;
}

export const HealthScore = ({
  healthScore,
  previousHealthScore,
  size = "md",
  wrap = "wrap",
  rowCount = 2,
  columnGap = 2,
}: HealthScoreProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const healthScoreOrZero = healthScore || 0;
  const fillColor = getHealthScoreColor(healthScoreOrZero);
  const hasHalf = healthScoreOrZero % 1 > 0.3 && healthScoreOrZero % 1 < 0.7;

  const starSize = size === "md" ? 28 : 18;

  const stars = useMemo(() => {
    const result = [];

    for (let i = 1; i <= 10; i++) {
      if (hasHalf && i === Math.ceil(healthScoreOrZero)) {
        result.push(
          <Box key={i}>
            <HalfStarIcon size={starSize} fill={fillColor} />
          </Box>
        );
        continue;
      }

      const isFilled = i <= Math.round(healthScoreOrZero);
      result.push(
        <Box
          key={i}
          border="1px solid transparent"
          sx={{
            svg: {
              "-webkit-filter": isHovered && isFilled ? `drop-shadow( 0px 0px 4px ${fillColor})` : "",
              filter: isHovered && isFilled ? `drop-shadow( 0px 0px 4px ${fillColor})` : "",
            },
          }}
        >
          <StarIcon size={starSize} fill={isFilled ? fillColor : "lightGray"} />
        </Box>
      );
    }
    return result;
  }, [healthScoreOrZero, isHovered, fillColor, hasHalf, starSize]);

  const change = previousHealthScore
    ? Number((((previousHealthScore - healthScoreOrZero) * 100) / healthScoreOrZero).toFixed(1))
    : 0;

  const numberOfFullStars =
    healthScoreOrZero % 1 > 0.7 ? Math.round(healthScoreOrZero) : Math.floor(healthScoreOrZero);

  // Split stars into the number of rows required
  const starsPerRow = Math.ceil(10 / rowCount);
  const starRows = Array.from({ length: rowCount }, (_, i) =>
    stars.slice(i * starsPerRow, (i + 1) * starsPerRow)
  );

  return (
    <Box position="relative" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Tooltip
        label={
          healthScore !== undefined
            ? ` ${Math.round(healthScoreOrZero * 10) / 10}/10`
            : "No health score available"
        }
        width="100%"
        textAlign="center"
        background="white"
        color="black"
        border="1px solid"
        borderColor={fillColor}
        py={1.5}
        mt={-1.5}
      >
        <Box opacity={healthScore !== undefined ? 1 : 0.5}>
          <VStack>
            {wrap === "wrap" && (
              <>
                {starRows.map((row, i) => (
                  <HStack key={`star-row-${i}`} spacing={columnGap}>
                    {row}
                  </HStack>
                ))}
              </>
            )}

            {wrap === "nowrap" && <HStack>{stars}</HStack>}
          </VStack>
        </Box>
      </Tooltip>
    </Box>
  );
};
