import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Tooltip,
  HStack,
  GridItem,
  VStack,
  ResponsiveValue,
  Skeleton,
  Center,
  useDisclosure,
  Flex,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { IoHelpCircleOutline } from "react-icons/io5";
import { TimestampRange } from "../../lib/api/types/TimestampRange";
import { SecondaryButton } from "../button/SecondaryButton";
import OverviewTileDetailPopup, { OverviewTileMetric } from "./detail-view/OverviewTileDetailPopup";
import { NoDataOverviewTile } from "./NoDataOverviewTile";
import { NoDeviceOverviewTile } from "./NoDeviceOverviewTile";

export interface OverviewTileV2NoDeviceState {
  icon: JSX.Element;
  title: string;
  unlockText: string;
  text: string;
}
export interface OverviewTileV2NoDataState {
  title?: string;
  text?: string;
  icon?: JSX.Element;
}
export interface OverviewTileV2Props {
  heading: string;
  headerControl?: JSX.Element;
  children?: React.ReactNode;
  toolTipText?: string;
  colSpan?: ResponsiveValue<number | "auto">;
  rowSpan?: ResponsiveValue<number | "auto">;
  showNoDeviceState?: boolean;
  showNoDataState?: boolean;
  isLoading: boolean;
  noDeviceStateConfig: OverviewTileV2NoDeviceState;
  noDataStateConfig: OverviewTileV2NoDataState;
  reading: string | JSX.Element;
  subText?: string | JSX.Element;
  detailsMetric?: OverviewTileMetric;
  propertyId: string;
  dateRange: TimestampRange;
  detailsMetricButton?: boolean;
  iconBg: string;
}

export const OverviewTileV2 = ({
  heading,
  headerControl,
  children,
  toolTipText,
  colSpan,
  rowSpan,
  showNoDeviceState,
  showNoDataState,
  isLoading,
  noDeviceStateConfig,
  noDataStateConfig,
  reading,
  subText,
  detailsMetric,
  propertyId,
  dateRange,
  detailsMetricButton,
  iconBg,
}: OverviewTileV2Props) => {
  const shouldShowNoDeviceTile = Boolean(!isLoading && showNoDeviceState);
  const shouldShowNoDataTile = Boolean(
    !isLoading && !showNoDeviceState && showNoDataState && noDataStateConfig
  );

  const { isOpen: detailsOpen, onOpen: onDetailsOpen, onClose: onDetailsClose } = useDisclosure();

  return (
    <>
      <GridItem
        shadow="lg"
        borderRadius={8}
        rowSpan={rowSpan}
        colSpan={colSpan}
        style={{ overflow: "hidden" }}
        h="100%"
      >
        {isLoading && <Skeleton w={"100%"} height={"100%"} borderRadius={8} />}
        {shouldShowNoDeviceTile && noDeviceStateConfig && (
          <NoDeviceOverviewTile
            text={noDeviceStateConfig.text}
            title={noDeviceStateConfig.title}
            icon={noDeviceStateConfig.icon}
            unlockWithDevice={noDeviceStateConfig.unlockText}
          />
        )}
        {shouldShowNoDataTile && (
          <NoDataOverviewTile
            title={noDataStateConfig.title || heading}
            text={noDataStateConfig.text}
            icon={noDataStateConfig.icon}
          />
        )}
        {Boolean(!isLoading && !shouldShowNoDeviceTile && !shouldShowNoDataTile) && (
          <Box
            // h={"268px"}
            height="100%"
            shadow="md"
            bg="white"
            borderRadius={8}
            borderWidth={1}
            borderColor="gray.50"
            display="flex"
            flexDirection="column"
          >
            <Box
              display={"flex"}
              flexWrap="wrap"
              borderBottomWidth={1}
              borderBottomColor="gray.50"
              py={3}
              px={3}
              mb={4}
              justifyContent="space-between"
            >
              <Box
                flex={1}
                alignItems="center"
                display={"flex"}
                flexDirection="row"
                justifyContent={"space-between"}
              >
                <Box display={"flex"} alignItems={"flex-start"} flexDirection="column">
                  <Box display={"flex"} flexDirection={"row"} alignItems="center">
                    <Text pr={1} fontSize={15} fontWeight={600} color="blue.900">
                      {heading}
                    </Text>
                    {Boolean(toolTipText) && (
                      <Tooltip p={4} shouldWrapChildren placement="bottom" label={toolTipText}>
                        <IoHelpCircleOutline size={15} />
                      </Tooltip>
                    )}
                  </Box>
                  {detailsMetricButton && (
                    <Flex alignItems={"center"} flexDirection={"row"} as={"button"} onClick={onDetailsOpen}>
                      <Text color={"gray.300"} _hover={{ textDecoration: "underline" }}>
                        View details
                      </Text>
                      <ChevronRightIcon height={5} width={5} />
                    </Flex>
                  )}
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                justifyContent={"center"}
                height={"44px"}
                width={"44px"}
                bg={iconBg}
                borderRadius={8}
                mr={2}
                style={{ background: iconBg }}
              >
                {noDeviceStateConfig.icon}
              </Box>
              {headerControl}
            </Box>
            <Flex alignItems="center" flexDir="column" px={4} pb={4}>
              {children}
              <Box>
                <Text textAlign="center">{reading}</Text>
                {subText && (
                  <Text textAlign="center" fontWeight={400}>
                    {subText}
                  </Text>
                )}
              </Box>
            </Flex>
            <Box>
              {detailsOpen && detailsMetric && (
                <OverviewTileDetailPopup
                  isOpen={detailsOpen}
                  onClose={onDetailsClose}
                  metric={detailsMetric}
                  propertyId={propertyId}
                  dateRange={dateRange}
                />
              )}
            </Box>
          </Box>
        )}
      </GridItem>
    </>
  );
};
