import { Text } from "@chakra-ui/react";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import CarbonIndexIcon from "../../icons/empty-state-metric-icons/CarbonIndexIcon";
import { CircuitNodeWithUsage } from "../../../lib/api/types/CircuitNode";
import { OrgSettingsTypes } from "../../../lib/api/auth/OrgSettingService";
import numeral from "numeral";
import { useAuth } from "../../../lib/auth/useAuth";

const CarbonEmissionsExplainText = () => (
  <Text>
    Our Carbon Impact metric is here to help you see how much operational carbon your building is using.
    <br />
    <br />
    It’s an easy way for you to keep an eye on the carbon footprint of your building, and find ways to reduce
    it. By keeping track of your carbon impact, you can help your organisation achieve its sustainability
    goals, and make sure your building is doing its part for the environment.
    <br />
    <br />
    And that’s not all! By keeping track of your carbon impact, you’ll also be able to find opportunities for
    carbon credits. These credits can be used to offset your building’s carbon emissions, or even to comply
    with carbon trading schemes. It’s a win-win for you and the environment!
    <br />
    <br />
  </Text>
);

const CarbonEmissionsDetails = ({
  propertyId,
  dateRange,
}: {
  propertyId: string;
  dateRange: TimestampRange;
}) => {
  const { orgSettings } = useAuth();
  const CO2E_PER_KWH = orgSettings[OrgSettingsTypes.CO2_PER_KWH] as number;
  // Convert from grams CO₂e/kWh to kg CO₂e/kWh
  const KGCO2E_PER_KWH = CO2E_PER_KWH ? CO2E_PER_KWH / 1000 : 0;

  return (
    <OverviewTileDetailView
      title="Carbon Impact"
      titleIcon={<CarbonIndexIcon size={16} />}
      shortDescription={<CarbonEmissionsExplainText />}
      propertyId={propertyId}
      roomStatsTitle=""
      dateRange={dateRange}
      renderEnergyView={(circuitNode: CircuitNodeWithUsage) => (
        <>
          {circuitNode.circuits.filter((c) => c.device).length > 0 && circuitNode.usage
            ? numeral(circuitNode.usage.totalkWh * KGCO2E_PER_KWH).format("0.00") + " kg CO₂e"
            : "No data"}
        </>
      )}
    />
  );
};

export default CarbonEmissionsDetails;
