import { Flex, Text } from "@chakra-ui/react";
import { RoomInsights } from "../../../lib/api/types/Insights";
import { gaussianRound } from "../../../lib/utils/gaussianRound";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { HealthScore } from "../HealthScore";
import { BsStarFill } from "react-icons/bs";

const renderRoomGraph = (roomInsights: RoomInsights) => {
  const { healthScore } = roomInsights.insights;

  if (healthScore === undefined) {
    return <Text>Unavailable for this room</Text>;
  }

  return (
    <Flex alignItems="center">
      <Text width={10} mr={2}>
        {gaussianRound(healthScore * 10) / 10}
      </Text>
      <HealthScore healthScore={healthScore} size="sm" wrap="nowrap" />
    </Flex>
  );
};

const HealthScoreExplainText = () => (
  <Text>
    Our Health Score helps you to measure of the total performance of your building over time. It’s like the
    continuous heart-beat of your building or a regular check-up by your doctor.
    <br />
    <br />
    The Health Score is calculated by the percentage of time spent your areas spend within the health ranges
    for temperature, relative humidity, and carbon dioxide during occupied times. We also factor in thermal
    comfort levels and mould risk.
    <br />
    <br />
    The Health Score not only a great way to understand your buildings performance at a glance, but also
    enables you to benchmark overall performance from month to month, season to season and on an annual basis.
  </Text>
);

const HealthScoreDetails = ({ propertyId, dateRange }: { propertyId: string; dateRange: TimestampRange }) => {
  return (
    <OverviewTileDetailView
      title="Health Score"
      titleIcon={<BsStarFill color="#6DC96E" size={16} />}
      shortDescription="Our Health Score helps you to measure of the total performance of your building over time. It’s like the continuous heart-beat of your building or a regular check-up by your doctor."
      longDescription={<HealthScoreExplainText />}
      showMoreCaption="Learn about our Health Score"
      propertyId={propertyId}
      renderRoomGraph={renderRoomGraph}
      sortRoomsByWorst={(a, b) => a.insights.healthScore - b.insights.healthScore}
      roomStatsTitle="Health score"
      filter={(insights: RoomInsights) => insights?.insights.airborneIndexPercentages !== undefined}
      dateRange={dateRange}
    />
  );
};

export default HealthScoreDetails;
