import { Button } from "@chakra-ui/button";
import { Box, HStack, Text, Flex } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { useIFrameMessaging } from "../../lib/auth/useIFrameMessaging";
import { startCase } from "lodash";

import LockedIcon from "../icons/LockedIcon";
import MotionBox from "../motion/MotionBox";

interface NoDeviceOverviewTileProps {
  unlockWithDevice: string;
  icon: JSX.Element;
  title: string;
  text: string;
  customHeight?: string;
}

export const NoDeviceOverviewTile = ({
  unlockWithDevice,
  icon,
  title,
  text,
  customHeight = "100%",
}: NoDeviceOverviewTileProps) => {
  const iFrameMessaging = useIFrameMessaging();

  const handleContactUsClicked = () => {
    let deviceText = unlockWithDevice.replace("an ", "");
    deviceText = deviceText.replace("a ", "");

    if (iFrameMessaging) {
      iFrameMessaging.sendUserAction("contact_us_clicked", { device_type: startCase(deviceText) });
    } else {
      window.location.href =
        "mailto:support@tetherhq.com?subject=I%27d%20like%20more%20information%20about%20your%20" +
        deviceText +
        "s";
    }
  };

  return (
    <>
      <Box
        borderRadius={8}
        overflow="hidden"
        sx={{
          "&:hover": { boxShadow: "lg" },
        }}
        h={customHeight}
      >
        <MotionBox
          display="flex"
          flexDirection="column"
          borderRadius={8}
          borderWidth={1}
          borderColor="gray.100"
          h="100%"
          sx={{
            "&:hover": {
              borderColor: "#E6E6E6",
              ".hideWhenHovered": {
                display: "none",
              },
            },
          }}
          whileHover="hover"
          overflow="hidden"
        >
          <MotionBox layout display="flex" justifyContent="flex-end">
            <Box bg="#E6E6E6" borderBottomLeftRadius="lg" borderTopRightRadius="4px">
              <MotionBox
                transition={{ duration: 0.4 }}
                initial={{ opacity: 0, translateX: 50, display: "none" }}
                variants={{
                  hover: { opacity: 1, translateX: 0, display: "block" },
                }}
              >
                <HStack mr={2}>
                  <LockedIcon />
                  <Text>Unlock with {unlockWithDevice}</Text>
                </HStack>
              </MotionBox>
              <Box className="hideWhenHovered">
                <LockedIcon />
              </Box>
            </Box>
          </MotionBox>
          <Flex
            flex={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            pb={"12px"}
            mx="27px"
          >
            <Flex flexDirection={"row"} textAlign="center" justifyContent="center">
              <Tooltip label={`${text} ${title}`}>
                <Text as="span" color="grey.600" noOfLines={2}>
                  <Box
                    as="span"
                    verticalAlign="text-top"
                    display="inline-block"
                    pr={2}
                    mt={0}
                    sx={{ svg: { display: "inline-block" } }}
                  >
                    {icon}
                  </Box>
                  {text}
                  <Text as="span" display="inline" fontWeight="bold">
                    {title}
                  </Text>
                </Text>
              </Tooltip>
            </Flex>
            <Button mt={2} w={"120px"} mb="0px" variant="outline" onClick={handleContactUsClicked}>
              <Text>Contact Us</Text>
            </Button>
          </Flex>
        </MotionBox>
      </Box>
    </>
  );
};
