import * as React from "react";

const LockedIcon = ({ width = 27, height = 25 }: { width?: number; height?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 27 25">
    <path
      d="M10.443 17.209h5.114c.693 0 1.03-.344 1.03-1.096V12.17c0-.676-.278-1.025-.853-1.085V9.732c0-2.025-1.327-3.003-2.734-3.003s-2.734.978-2.734 3.003v1.38c-.526.08-.854.425-.854 1.058v3.943c0 .752.338 1.096 1.031 1.096Zm.688-7.59c0-1.348.865-2.062 1.869-2.062s1.87.714 1.87 2.062v1.461l-3.74.005V9.62Z"
      fill="#595F6A"
    />
  </svg>
);

export default LockedIcon;
