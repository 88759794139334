import { Box, Tooltip } from "@chakra-ui/react";

interface LinearPercentageChartSection {
  value?: number;
  color: string;
  title: string;
}

interface LinearPercentageChartProps {
  sections: LinearPercentageChartSection[];
}

export default function LinearPercentageChart({ sections }: LinearPercentageChartProps) {
  const valueIsPercent = sections.reduce((acc, section) => acc + (section.value || 0), 0) > 1;

  return (
    <Box
      display="flex"
      alignItems="center"
      height="8px"
      borderRadius="4px"
      overflow="hidden"
      backgroundColor="#E2E8F0"
      width="100%"
    >
      {sections.map(({ title, value = 0, color }, index) => (
        <Tooltip
          key={`linear-chart-${index}-${title}`}
          label={
            title === "No Data" ? "No Data" : `${title}: ${Math.round(valueIsPercent ? value : value * 100)}%`
          }
        >
          <Box flex={value} height="100%" backgroundColor={color} />
        </Tooltip>
      ))}
    </Box>
  );
}
