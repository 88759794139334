import { Text } from "@chakra-ui/react";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import EnergyCostIndexIcon from "../../icons/empty-state-metric-icons/EnergyCostIndexIcon";
import { OrgSettingsTypes } from "../../../lib/api/auth/OrgSettingService";
import { useAuth } from "../../../lib/auth/useAuth";
import { CircuitNodeWithUsage } from "../../../lib/api/types/CircuitNode";
import CurrencyFormat from "../../currency/CurrencyFormat";

const EnergyCostExplainText = () => (
  <Text>
    Our Energy Cost metric helps you see the estimated total cost of energy for your building within a given
    period. <br />
    <br />
    By tracking energy cost, an organisation can better understand its energy expenses and develop accurate
    budget and forecasting models. This can help the organisation to make more informed decisions about future
    energy investments and cost-saving measures.
    <br />
    <br />
    In addition, tracking energy cost can also help identify any potential errors in energy billing, such as
    incorrect meter readings, tariffs or taxes. This can help the organisation to correct any inaccuracies and
    avoid overpaying for energy.
    <br />
    <br />
  </Text>
);

const EnergyCostDetails = ({ propertyId, dateRange }: { propertyId: string; dateRange: TimestampRange }) => {
  const { orgSettings } = useAuth();

  const PRICE_PER_KWH = orgSettings[OrgSettingsTypes.COST_PER_KWH] as number;

  return (
    <OverviewTileDetailView
      title="Energy Cost"
      titleIcon={<EnergyCostIndexIcon fill="#7133C3" size={16} />}
      shortDescription={<EnergyCostExplainText />}
      propertyId={propertyId}
      roomStatsTitle=""
      dateRange={dateRange}
      renderEnergyView={(circuitNode: CircuitNodeWithUsage) => (
        <>
          {circuitNode.circuits.filter((c) => c.device).length > 0 && circuitNode.usage ? (
            <>
              <CurrencyFormat value={circuitNode.usage.totalkWh * PRICE_PER_KWH} />
            </>
          ) : (
            "No data"
          )}
        </>
      )}
    />
  );
};

export default EnergyCostDetails;
