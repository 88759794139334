import { Flex, Text } from "@chakra-ui/react";
import { PropertyInsights, RoomInsights } from "../../../lib/api/types/Insights";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { gaussianRound } from "../../../lib/utils/gaussianRound";
import { RiskColors } from "../types/types";
import HorizontalInsightsGraph from "./HorizontalInsightsGraph";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { ProductivityIndexStatusText } from "../tiles/ProductivityIndexTile";
import { FaBrain } from "react-icons/fa";
import ProductivityChart from "../../productivity-chart/ProductivityChart";

const renderRoomProductivityGraph = (roomInsights: RoomInsights) => {
  const { productivityPercentage } = roomInsights.insights;

  if (productivityPercentage === undefined) {
    return <Text>Unavailable for this room</Text>;
  }

  const productivePercent = gaussianRound(productivityPercentage * 100);
  const unproductivePercent = gaussianRound((1 - productivityPercentage) * 100);

  return (
    <Flex alignItems="center">
      <Text width={10} mr={2}>
        {unproductivePercent}%
      </Text>
      <HorizontalInsightsGraph
        segments={[
          { label: "Unproductive", value: unproductivePercent, color: RiskColors.High },
          { label: "Productive", value: productivePercent, color: RiskColors.Low },
        ]}
      />
    </Flex>
  );
};

const renderOverviewGraph = (propertyInsights: PropertyInsights) => {
  const { productivityPercentage } = propertyInsights.insights;

  if (!productivityPercentage) {
    return <></>;
  }

  return (
    <Flex alignItems="center">
      <ProductivityChart productivityPercentage={productivityPercentage} size="sm" type="radial" />
      <ProductivityIndexStatusText productivityPercentage={productivityPercentage} />
    </Flex>
  );
};

const ProductivityIndexExplainText = () => (
  <Text>
    Our Focus Index metric helps you see how conducive your spaces are to learning and working.
    <br />
    <br />
    We use CO2 as a measure of focus because high levels of CO2 can impact how well people can concentrate and
    be productive.
    <br />
    <br />
    High CO2 levels can lead to headaches, fatigue, and difficulty concentrating, which can all affect focus
    and productivity.
    <br />
    <br />
    By monitoring the CO2 levels we can can help make sure the people in your space people feel better and be
    more productive.
    <br />
    <br />
    It's important for organisations to monitor the air quality and take steps to improve it, like increasing
    ventilation, to make sure the space is as healthy and productive as possible.
    <br />
    <br />
    <Text mb={2} fontWeight="bold">
      Focus Index
    </Text>
    <Text fontWeight="bold">Focussed</Text>
    CO2 levels between 400ppm-1000ppm  <br />
    <Text mt={2} fontWeight="bold">
      Unfocussed
    </Text>
    CO2 levels 1000ppm+
    <br />
  </Text>
);

const ProductivityIndexDetails = ({
  propertyId,
  dateRange,
}: {
  propertyId: string;
  dateRange: TimestampRange;
}) => {
  return (
    <OverviewTileDetailView
      title="Focus Index"
      titleIcon={<FaBrain color="#F9C9C0" />}
      shortDescription="Our Focus Index metric helps you see how conducive your spaces are to learning and working."
      longDescription={<ProductivityIndexExplainText />}
      showMoreCaption="Learn about Focus"
      propertyId={propertyId}
      renderRoomGraph={renderRoomProductivityGraph}
      renderOverviewGraph={renderOverviewGraph}
      sortRoomsByWorst={(a, b) =>
        (a.insights.productivityPercentage || 0) - (b.insights.productivityPercentage || 0)
      }
      roomStatsTitle="% of time unproductive"
      filter={(insights: RoomInsights) => insights?.insights.productivityPercentage !== undefined}
      dateRange={dateRange}
    />
  );
};

export default ProductivityIndexDetails;
