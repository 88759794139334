import { Flex, Tooltip } from "@chakra-ui/react";

export interface HorizontalGraphSegment {
  key?: string;
  label: string;
  value: number;
  color: string;
  group?: string;
}

interface HorizontalInsightsGraphProps {
  segments: HorizontalGraphSegment[];
}

const HorizontalInsightsGraph = ({ segments }: HorizontalInsightsGraphProps) => {
  return (
    <Flex height="100%" borderRadius="xl" width="100%" overflow="hidden">
      {segments.map((segment, index) => {
        // Used to determine whether the current group has data and, if there is a following group, whether the following group
        // has data for adding padding between groups.
        const hasFollowingSegmentGroup =
          index < segments.length - 1 && segment.group !== segments[index + 1].group;
        const groupHasData = Boolean(
          hasFollowingSegmentGroup && segments.find((s) => s.group === segment.group && s.value > 0)
        );
        const followingGroupHasData = Boolean(
          hasFollowingSegmentGroup && segments.find((s, i) => i > index && s.value > 0)
        );

        return (
          <Tooltip key={segment.key || segment.label} label={`${segment.label} - ${segment.value}%`}>
            <Flex
              backgroundColor={segment.color}
              height="20px"
              flex={segment.value}
              // Padding to separate groups
              mr={groupHasData && followingGroupHasData ? 1 : 0}
            />
          </Tooltip>
        );
      })}
    </Flex>
  );
};

export default HorizontalInsightsGraph;
