import { Badge, Box, Flex, Heading, Text, Tooltip } from "@chakra-ui/react";
import {
  RoomInsights,
  ComfortIndexPercentages,
  PropertyInsights,
  ComfortIndexCategoryMap,
  ComfortIndexCategory,
} from "../../../lib/api/types/Insights";
import { gaussianRound } from "../../../lib/utils/gaussianRound";
import HorizontalInsightsGraph, { HorizontalGraphSegment } from "./HorizontalInsightsGraph";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import {
  ComfortScore,
  ComfortScoreColor,
  ComfortScoreColorBg,
  ComfortScoreEmojis,
  getComfortScoreDisplayText,
} from "../../../lib/api/types/Room";
import { getUncomfortPercentTextElement } from "../tiles/ComfortScoreTile";
import { BsWind } from "react-icons/bs";
import { ComfortScoreTag } from "../../property/ComfortScoreTag";
import { ComfortScoreChart } from "../../comfort-score-chart/ComfortScoreChart";

const renderRoomGraph = (roomInsights: RoomInsights) => {
  const { comfortIndexPercentages } = roomInsights.insights;

  if (comfortIndexPercentages === undefined) {
    return <Text>Unavailable for this room</Text>;
  }

  let uncomfortablePercentage = 0;

  const segments = Object.keys(comfortIndexPercentages).map((key) => {
    const comfortScore = ComfortIndexCategoryMap[key as ComfortIndexCategory];
    const color = ComfortScoreColor[comfortScore];

    const percentage = gaussianRound(
      100 * comfortIndexPercentages[key as keyof ComfortIndexPercentages] || 0
    );
    const isUncomfortable = comfortScore !== ComfortScore.PerfectComfort;

    if (isUncomfortable) {
      uncomfortablePercentage += percentage;
    }
    return {
      key,
      label: getComfortScoreDisplayText(comfortScore),
      value: percentage,
      color: color || "",
      group: isUncomfortable ? "Uncomfortable" : "Comfortable",
    };
  });

  const sortedByComfortOrder = Object.values(ComfortIndexCategory)
    .map((key) => segments.find((s) => s.key === key))
    .filter((s) => s !== undefined) as HorizontalGraphSegment[];

  const sortedByTimeAtLevel = [...sortedByComfortOrder].sort((a, b) => b.value - a.value);
  const iconSegment =
    sortedByTimeAtLevel[0].key === ComfortIndexCategory.perfectComfort && sortedByTimeAtLevel[0].value !== 100
      ? sortedByTimeAtLevel[1]
      : sortedByTimeAtLevel[0];

  return (
    <Flex alignItems="center">
      <Text width={10} mr={2} ml={1} fontWeight="bold">
        {gaussianRound(uncomfortablePercentage)}%
      </Text>
      {iconSegment.key !== undefined && (
        <Tooltip label={iconSegment.label}>
          <Badge
            height="20px"
            borderRadius="lg"
            mr={2}
            pt="3px"
            px={2}
            backgroundColor={
              ComfortScoreColorBg[ComfortIndexCategoryMap[iconSegment.key as ComfortIndexCategory]]
            }
          >
            {ComfortScoreEmojis[ComfortIndexCategoryMap[iconSegment.key as ComfortIndexCategory]]}
          </Badge>
        </Tooltip>
      )}
      <HorizontalInsightsGraph segments={sortedByComfortOrder} />
    </Flex>
  );
};

const renderOverviewGraph = (propertyInsights: PropertyInsights) => {
  const { comfortIndexPercentages } = propertyInsights.insights;

  return (
    <Flex alignItems="center">
      <ComfortScoreChart comfortIndexPercentages={comfortIndexPercentages} size="sm" type="radial" />
      {getUncomfortPercentTextElement(comfortIndexPercentages["perfectComfort"], comfortIndexPercentages)}
    </Flex>
  );
};

const ComfortIndexExplainText = () => (
  <Text>
    Our Comfort Index metric helps you keep track of how comfortable your spaces are for everyone inside.
    <br />
    <br />
    We use temperature and humidity as indicators of comfort because they can really affect how people feel.
    For example, if it’s really hot and humid, it can make the air feel stuffy and uncomfortable. On the other
    hand, if it’s cold and dry, it can make the air feel chilly and uncomfortable.
    <br />
    <br />
    By monitoring temperature and humidity, we can help make sure your space is comfortable for everyone by
    keeping these factors at a good level.
    <br />
    <br />
    The Comfort Index not only helps you understand the comfort quality of the air, but it also has alerts to
    let you know when the air becomes uncomfortable, so you can take steps to make it comfortable again.
    <br />
    <br />
    <Heading size="sm">Comfort States</Heading>
    <Heading size="xs" mt={4} mb={2}>
      Uncomfortable
    </Heading>
    {Object.values(ComfortIndexCategory).map((category) => {
      if (category === "perfectComfort") {
        return null;
      }

      return (
        <Box key={category} display="flex" flexDirection="column">
          <ComfortScoreTag comfortScore={ComfortIndexCategoryMap[category]} m={0.5} />
        </Box>
      );
    })}
    <Heading size="xs" mt={4} mb={2}>
      Comfortable
    </Heading>
    <Flex flexDirection="column">
      <ComfortScoreTag comfortScore={ComfortScore.PerfectComfort} m={0.5} />
    </Flex>
  </Text>
);

const ComfortIndexDetails = ({
  propertyId,
  dateRange,
}: {
  propertyId: string;
  dateRange: TimestampRange;
}) => {
  return (
    <OverviewTileDetailView
      title="Comfort Index"
      titleIcon={<BsWind fill={ComfortScoreColor["Muggy and Hot"]} size={16} />}
      shortDescription="Our Comfort Index metric helps you keep track of how comfortable your spaces are for everyone inside."
      longDescription={<ComfortIndexExplainText />}
      showMoreCaption="Learn about the Comfort States"
      propertyId={propertyId}
      renderRoomGraph={renderRoomGraph}
      renderOverviewGraph={renderOverviewGraph}
      sortRoomsByWorst={(a, b) =>
        (a.insights.comfortIndexPercentages.perfectComfort || 0) -
        (b.insights.comfortIndexPercentages.perfectComfort || 0)
      }
      roomStatsTitle="% of time uncomfortable"
      filter={(insights: RoomInsights) => insights?.insights.comfortIndexPercentages !== undefined}
      dateRange={dateRange}
    />
  );
};

export default ComfortIndexDetails;
