import * as React from "react";

const HalfStarIcon = ({ fill, size }: { fill: string; size: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 28 26">
    <defs>
      <linearGradient id="halfStar">
        <stop offset="50%" stopColor={fill} />
        <stop offset="50%" stopColor="#C9CACC" />
      </linearGradient>
    </defs>
    <path
      d="m14 0 3.143 9.674h10.172l-8.23 5.979 3.144 9.673L14 19.347l-8.229 5.98 3.143-9.675L.685 9.675h10.172L14 0Z"
      fill="url(#halfStar)"
    />
  </svg>
);

export default HalfStarIcon;
