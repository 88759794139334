import { ResponsiveValue, Text } from "@chakra-ui/react";
import { GiMushrooms } from "react-icons/gi";
import { getMouldRiskLevel, MouldRisk } from "../../../lib/utils/calculateDaysToMould";
import { OverviewTileV2 } from "../OverviewTileV2";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { OverviewTileMetric } from "../detail-view/OverviewTileDetailPopup";
import { MouldRiskPercentages } from "../../../lib/api/types/Insights";
import { StatsPieChart } from "./StatsPieChart";
import { FullPropertyRoomReading } from "../../property-graph/GraphUtils";
import numeral from "numeral";
import MouldIndexChart from "../../mould-risk-chart/MouldRiskChart";

const NO_DEVICE_STATE = {
  text: "Track the risk of mould growth with ",
  icon: <GiMushrooms size={20} fill="#FB5169" />,
  unlockText: "an EnviroQ",
  title: "Mould Index",
};

const NO_DATA_STATE = { icon: <GiMushrooms size={16} fill="lightGray" /> };

interface MouldIndexTileProps {
  hasEnviroQs: boolean;
  colSpan?: ResponsiveValue<number | "auto">;
  rowSpan?: ResponsiveValue<number | "auto">;
  isLoading: boolean;
  mouldRiskPercentages?: MouldRiskPercentages;
  propertyId: string;
  dateRange: TimestampRange;
  detailsMetricButton?: boolean;
  isRoom: boolean;
}

export const MouldIndexTile = ({
  hasEnviroQs,
  colSpan,
  rowSpan,
  isLoading,
  mouldRiskPercentages,
  propertyId,
  dateRange,
  detailsMetricButton,
  isRoom,
}: MouldIndexTileProps) => {
  return (
    <OverviewTileV2
      subText={""}
      reading={""}
      showNoDeviceState={!mouldRiskPercentages && !hasEnviroQs}
      showNoDataState={!mouldRiskPercentages}
      noDeviceStateConfig={NO_DEVICE_STATE}
      noDataStateConfig={NO_DATA_STATE}
      isLoading={isLoading}
      colSpan={colSpan}
      rowSpan={rowSpan}
      heading="Mould Index"
      toolTipText="The mould index is a measure of the probability of mould growth in this environment."
      dateRange={dateRange}
      propertyId={propertyId}
      detailsMetric={OverviewTileMetric.MouldIndex}
      detailsMetricButton={detailsMetricButton}
      iconBg={"rgba(233, 21, 59, 0.1)"}
    >
      <>
        <MouldIndexChart type="radial" mouldRiskPercentages={mouldRiskPercentages} />
        {getMouldRiskStatusText(isRoom, mouldRiskPercentages)}
      </>
    </OverviewTileV2>
  );
};

export const getMouldRiskStatusText = (
  isRoom: boolean,
  mouldRiskPercentages?: MouldRiskPercentages,
  fontSize?: number | string,
  riskBold = true
) => {
  if (mouldRiskPercentages !== undefined) {
    const totalAtRisk =
      (mouldRiskPercentages.mid || 0) +
      (mouldRiskPercentages.high || 0) +
      (mouldRiskPercentages.veryHigh || 0);

    return (
      <Text textAlign="center" ml={2} fontSize={fontSize}>
        {(totalAtRisk > 0 && (
          <>
            <b>{numeral(totalAtRisk).format("0.[0]%")}</b> of time{" "}
            {riskBold ? <b>at risk of mould growth</b> : "at risk of mould growth"}
          </>
        )) || (
          <>
            You have {riskBold ? <b>no mould risk</b> : "no mould risk"} {isRoom ? "for" : "on"} this{" "}
            {!isRoom ? "property" : "area"}{" "}
          </>
        )}
      </Text>
    );
  }
};
