import * as React from "react";

const StarIcon = ({ fill, size }: { fill: string; size: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 28 26" fill="none">
    <path
      d="m14 0 3.143 9.674h10.172l-8.23 5.979 3.144 9.673L14 19.347l-8.229 5.98 3.143-9.675L.685 9.675h10.172L14 0Z"
      fill={fill}
    />
  </svg>
);

export default StarIcon;
