import { Box } from "@chakra-ui/react";

export const BlackToggleButton = ({
  isSelected,
  title,
  onClick,
}: {
  isSelected: boolean;
  title: string;
  onClick: () => void;
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      backgroundColor={isSelected ? "black" : undefined}
      color={isSelected ? "white" : undefined}
      key={title}
      onClick={onClick}
      _hover={{
        opacity: 0.7,
      }}
      py={1}
      px={2}
      cursor="pointer"
      borderRadius="md"
      borderWidth={1}
      borderColor={isSelected ? "gray.200" : "gray.200"}
      fontSize="14px"
      fontWeight={600}
    >
      {title}
    </Box>
  );
};
