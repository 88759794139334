import { ChevronRightIcon } from "@chakra-ui/icons";
import { Alert, AlertIcon, Box, filter, Flex, Grid, GridItem, Heading, Link, Text } from "@chakra-ui/react";
import moment from "moment";
import { useRouter } from "next/router";
import React from "react";
import { useState } from "react";
import { useGetInsightsForMultipleRooms, useGetPropertyInsights } from "../../../lib/api/insights/hooks";
import { useGetMonitoringHours } from "../../../lib/api/monitoring-hours/hooks";
import { useGetCircuitNodeTreeWithUsage, useGetRooms } from "../../../lib/api/properties/hooks";
import { CircuitNodeWithUsage } from "../../../lib/api/types/CircuitNode";
import { PropertyInsights, RoomInsights } from "../../../lib/api/types/Insights";
import { Room } from "../../../lib/api/types/Room";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { flattenCircuitNodesWithBreadcrumbs } from "../../../lib/utils/circuitNodeUtils";
import { getMonitoringHoursDisplayText } from "../../../lib/utils/getMonitoringHoursDisplayText";
import { RoomIcon } from "../../create-room/room-icon/RoomIcon";
import { Loader } from "../../loading/Loader";
import DateRangeControls, {
  getResolutionDaysForRange,
  MomentDateRangeOptional,
} from "../../property/DateRangeControls";
import AreasSortDropdown, { AreaSortOptionKey } from "./AreasSortDropdown";

interface OverviewTileDetailView {
  title: string;
  titleIcon?: JSX.Element;
  shortDescription: string | JSX.Element;
  longDescription?: string | JSX.Element;
  showMoreCaption?: string | JSX.Element;
  propertyId: string;
  dateRange?: TimestampRange;
  filter?: (insights: RoomInsights) => boolean;
  sortRoomsByWorst?: (roomAInsights: RoomInsights, roomBInsights: RoomInsights, rooms: Room[]) => number;
  renderRoomGraph?: (insights: RoomInsights) => JSX.Element;
  renderOverviewGraph?: (insights: PropertyInsights) => JSX.Element;
  renderEnergyView?: (circuitNode: CircuitNodeWithUsage) => JSX.Element;
  roomStatsTitle: string;
}

const OverviewTileDetailView = ({
  title,
  titleIcon,
  shortDescription,
  longDescription,
  showMoreCaption,
  propertyId,
  filter,
  sortRoomsByWorst,
  renderRoomGraph,
  renderOverviewGraph,
  renderEnergyView,
  roomStatsTitle,
  dateRange: initialDateRange,
}: OverviewTileDetailView) => {
  const router = useRouter();

  const [dateRange, setDateRange] = useState({
    fromTimestamp:
      initialDateRange?.fromTimestamp || moment().subtract(1, "days").startOf("hour").toISOString(),
    toTimestamp: initialDateRange?.toTimestamp || moment().endOf("hour").toISOString(),
  });

  const [sortMode, setSortMode] = useState<AreaSortOptionKey>(AreaSortOptionKey.Worst);

  const [descriptionExpanded, setDescriptionExpanded] = useState(false);

  const setQueryParamsForDateRange = (dateRange: MomentDateRangeOptional) => {
    if (!dateRange.endDate || !dateRange.startDate) return;

    setDateRange({
      fromTimestamp: dateRange.startDate.toISOString(),
      toTimestamp: dateRange.endDate.toISOString(),
    });
  };

  const { monitoringHours, isLoading: isLoadingMonitoringHours } = useGetMonitoringHours();

  const {
    propertyInsights,
    isLoading: propertyInsightsLoading,
    error: propertyInsightsError,
  } = useGetPropertyInsights(propertyId, {
    startDate: dateRange.fromTimestamp,
    endDate: dateRange.toTimestamp,
  });
  const { rooms, isLoading: roomsLoading, error: roomsError } = useGetRooms(propertyId);
  const {
    roomInsightsArray,
    isLoading: roomInsightsLoading,
    errors: roomInsightsErrors,
  } = useGetInsightsForMultipleRooms(rooms?.map((r) => r.id) || [], {
    startDate: dateRange.fromTimestamp,
    endDate: dateRange.toTimestamp,
  });

  const { circuitNodeTree, isLoading: powerIsLoading, error: powerError } = useGetCircuitNodeTreeWithUsage(
    renderEnergyView ? propertyId : undefined, // only fetch if we need it
    dateRange
  );

  const sortedRooms = sortRoomsByWorst
    ? roomInsightsArray
        .filter((insights) => (filter !== undefined ? filter(insights) : true))
        .sort((a, b) => sortRoomsByWorst(a, b, rooms || []))
    : roomInsightsArray;
  if (sortMode === AreaSortOptionKey.Best) {
    sortedRooms.reverse();
  }

  const isLoading =
    roomsLoading || roomInsightsLoading || propertyInsightsLoading || isLoadingMonitoringHours;
  const hasError = Boolean(
    roomsError || propertyInsightsError || (roomInsightsErrors.length && roomInsightsErrors[0])
  );

  return (
    <Box>
      <Flex flexDir="column" p={5}>
        <Box>
          <Flex alignItems="center">
            {titleIcon && <Box mr={2}>{titleIcon}</Box>}
            <Heading size="md">{title}</Heading>
          </Flex>
          {shortDescription && !descriptionExpanded && <Text mt={4}>{shortDescription}</Text>}
          {longDescription && (
            <Box mt={4}>
              {!descriptionExpanded && (
                <Link onClick={() => setDescriptionExpanded(true)} display="inline">
                  {showMoreCaption && (
                    <Text fontWeight="bold" display="inline" mr={2}>
                      {showMoreCaption}
                    </Text>
                  )}
                  <Text display="inline" fontSize="sm" color="blue.400">
                    Show More...
                  </Text>
                </Link>
              )}
              {descriptionExpanded && (
                <>
                  {longDescription}
                  <Box mt={2}>
                    <Link onClick={() => setDescriptionExpanded(false)} fontSize="sm" color="blue.400">
                      Show Less...
                    </Link>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
        {(renderOverviewGraph || renderRoomGraph) && (
          <Box borderBottom="1px solid" borderBottomColor="gray.50" my={5} mx={-5} />
        )}
        {renderOverviewGraph && propertyInsights !== undefined && (
          <Box background="gray.50" borderRadius="lg" mb={5}>
            {!isLoading && !hasError && renderOverviewGraph(propertyInsights)}
            {isLoading && <Loader my={2} />}
          </Box>
        )}
        {renderRoomGraph && (
          <>
            <Flex>
              <DateRangeControls
                setNewDateRange={setQueryParamsForDateRange}
                selectedDateRange={{
                  startDate: moment(dateRange.fromTimestamp),
                  endDate: moment(dateRange.toTimestamp),
                }}
                buttonOptions={[
                  { hours: 24, label: "24 hours" },
                  { hours: 7 * 24, label: "7 days" },
                  { hours: 30 * 24, label: "30 days" },
                ]}
              />
              {/* <AreasSortDropdown onOptionSelected={(option) => setSortMode(option.value as AreaSortOptionKey)} /> */}
              {monitoringHours !== undefined && monitoringHours.length > 0 && (
                <Flex alignItems="center" pl={5}>
                  <Text fontSize="sm">
                    <b>Occupied time:</b>{" "}
                    <Text display="inline" color="gray.400" fontSize="sm">
                      {getMonitoringHoursDisplayText(monitoringHours)}
                    </Text>
                  </Text>
                </Flex>
              )}
            </Flex>
            {isLoading && (
              <Box height={50} mt={10}>
                <Loader />
              </Box>
            )}
            {hasError && (
              <Box py={10}>
                <Alert status="error">
                  <AlertIcon /> There was an error loading data for your rooms.
                </Alert>
              </Box>
            )}
            {!isLoading && !hasError && (
              <Grid
                my={6}
                templateColumns="repeat(1, 1fr)"
                border="1px solid"
                borderColor="gray.50"
                borderRadius="md"
              >
                <Flex width="99%" borderBottom="1px solid" borderBottomColor="gray.50">
                  <Flex flex={1} p={2} fontWeight="bold">
                    Areas
                  </Flex>
                  <Text flex={1} p={2} color="gray.400">
                    {roomStatsTitle}
                  </Text>
                  <Box boxSize={8} />
                </Flex>
                {sortedRooms.length === 0 && (
                  <Text p={10} textAlign="center" color="gray.400">
                    No areas have data to show
                  </Text>
                )}
                {renderRoomGraph !== undefined &&
                  sortedRooms.map((roomInsights) => {
                    const room = rooms?.find((r) => r.id === roomInsights.roomId);

                    if (!room) {
                      return null;
                    }

                    return (
                      <Flex
                        key={roomInsights.roomId}
                        width="100%"
                        height="60px"
                        onClick={() => router.push(`/area/room/${room.id}`)}
                        _hover={{ backgroundColor: "gray.50" }}
                        cursor="pointer"
                        borderBottom="1px solid"
                        borderBottomColor="gray.50"
                        alignItems="center"
                      >
                        <Flex width="99%" alignItems="center">
                          <Flex flex={1} alignItems="center">
                            <Box p={4}>
                              <RoomIcon type={room.type} color="black" />
                            </Box>
                            {room.name}
                          </Flex>
                          <Box flex={1}>{renderRoomGraph(roomInsights)}</Box>
                        </Flex>
                        <ChevronRightIcon boxSize={8} color="gray.100" />
                      </Flex>
                    );
                  })}
              </Grid>
            )}
          </>
        )}

        {renderEnergyView && (
          <>
            <Flex>
              <DateRangeControls
                setNewDateRange={setQueryParamsForDateRange}
                selectedDateRange={{
                  startDate: moment(dateRange.fromTimestamp),
                  endDate: moment(dateRange.toTimestamp),
                }}
                buttonOptions={[
                  { hours: 24, label: "24 hours" },
                  { hours: 7 * 24, label: "7 days" },
                  { hours: 30 * 24, label: "30 days" },
                ]}
              />
            </Flex>
            {powerIsLoading && (
              <Box height={50} mt={10}>
                <Loader />
              </Box>
            )}
            {Boolean(powerError) && (
              <Box py={10}>
                <Alert status="error">
                  <AlertIcon /> There was an error loading data for your rooms.
                </Alert>
              </Box>
            )}
            {!powerIsLoading && !powerError && (
              <Grid
                my={6}
                templateColumns="repeat(1, 1fr)"
                border="1px solid"
                borderColor="gray.50"
                borderRadius="md"
              >
                <Flex width="99%" borderBottom="1px solid" borderBottomColor="gray.50">
                  <Flex flex={1} p={2} fontWeight="bold">
                    Circuit Locations
                  </Flex>
                  <Text flex={1} p={2} color="gray.400">
                    {roomStatsTitle}
                  </Text>
                  <Box boxSize={8} />
                </Flex>
                {sortedRooms.length === 0 && (
                  <Text p={10} textAlign="center" color="gray.400">
                    No circuit locations have data to show
                  </Text>
                )}
                {flattenCircuitNodesWithBreadcrumbs(circuitNodeTree).map((flatNode) => {
                  return (
                    <CircuitNodeDisplay
                      key={flatNode.id}
                      label={flatNode.breadcrumb}
                      node={flatNode.node as CircuitNodeWithUsage}
                      depth={0}
                      renderEnergyView={renderEnergyView}
                    />
                  );
                })}
              </Grid>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default OverviewTileDetailView;

interface CircuitNodeDisplayProps {
  node: CircuitNodeWithUsage;
  depth: number;
  label: string;
  renderEnergyView: (circuitNode: CircuitNodeWithUsage) => React.ReactNode;
}

const CircuitNodeDisplay = ({ label, node, depth = 0, renderEnergyView }: CircuitNodeDisplayProps) => {
  const router = useRouter();

  return (
    <Flex
      key={node.id}
      width="100%"
      height="60px"
      onClick={() => router.push(`/circuitnode/${node.id}`)}
      _hover={{ backgroundColor: "gray.50" }}
      cursor="pointer"
      borderBottom="1px solid"
      borderBottomColor="gray.50"
      alignItems="center"
      pl={depth * 10}
    >
      <Flex width="99%" alignItems="center">
        <Flex flex={1} alignItems="center" pl={2}>
          {label}
        </Flex>
        <Box flex={1} pr={5} textAlign="right">
          {renderEnergyView(node)}
        </Box>
      </Flex>
      <ChevronRightIcon boxSize={8} color="gray.100" />
    </Flex>
  );
  {
    /* {node.children.map((child) => (
        <CircuitNodeDisplay
          key={child.id}
          node={child}
          depth={depth + 1}
          renderEnergyView={renderEnergyView}
        />
      ))} */
  }
};
