import React from "react";
import { Box, HStack } from "@chakra-ui/react";
import { BlackToggleButton } from "./BlackToggleButton";

export type TimeButtonOptions = {
  hours: number;
  endDate?: string;
  label: string;
};

export const defaultTimeButtons: TimeButtonOptions[] = [
  // { hours: 12, label: "12 hours" },
  { hours: 24, label: "24 hours" },
  { hours: 7 * 24, label: "7 days" },
  { hours: 30 * 24, label: "30 days" },
];

interface PrimaryButtonProps {
  onSelect: (option: TimeButtonOptions) => void;
  selectedValue: TimeButtonOptions | null;
  overridden: boolean /* Set to true if another component has set the date range which overrides these buttons */;
  buttons?: TimeButtonOptions[];
}

export const TimeRangeButtons = ({ buttons, onSelect, selectedValue, overridden }: PrimaryButtonProps) => {
  return (
    <HStack bg="white">
      {(buttons || defaultTimeButtons).map((button) => {
        const isSelected = selectedValue === button && !overridden;

        return (
          <BlackToggleButton
            key={button.label}
            title={button.label}
            isSelected={isSelected}
            onClick={() => onSelect(button)}
          />
        );
      })}
    </HStack>
  );
};
