import { Box, Flex, ResponsiveValue, Text } from "@chakra-ui/react";
import { BsWind } from "react-icons/bs";
import { PropertyDetail } from "../../../lib/api/types/PropertyDetail";
import {
  ComfortScore,
  getComfortScoreColor,
  getComfortScoreColor2,
  getComfortScoreDisplayText,
} from "../../../lib/api/types/Room";
import { OverviewTileMetric } from "../detail-view/OverviewTileDetailPopup";
import { ComfortScore2 } from "../types/types";
import { OverviewTileV2 } from "../OverviewTileV2";
import { StatsPieChart } from "./StatsPieChart";
import { startCase } from "lodash";
import { gaussianRound } from "../../../lib/utils/gaussianRound";
import { PercentageDelta } from "../PercentageDelta";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import {
  ComfortIndexCategory,
  ComfortIndexCategoryMap,
  ComfortIndexPercentages,
} from "../../../lib/api/types/Insights";
import { ComfortScoreChart } from "../../comfort-score-chart/ComfortScoreChart";

const NO_DEVICE_STATE = {
  text: "Track how your building makes you feel with our ",
  icon: <BsWind fill="#4499FF" size={20} />,
  unlockText: "an EnviroQ",
  title: "Comfort Index",
};

const NO_DATA_STATE = {
  icon: <BsWind size={16} fill="lightGray" />,
};

const getComfortScoreBreakdown = (propertyInfo: PropertyDetail) => {
  const comfortData = propertyInfo.rooms
    .flatMap((r) => r.roomLocations)
    .map((r) => r.insights?.comfortScore)
    .filter((r) => Boolean(r))
    .reduce((prev: Record<ComfortScore, number>, current: ComfortScore | undefined) => {
      if (!prev![current!]) {
        prev[current!] = 0;
      }

      prev[current!] += 1;
      return {
        ...prev,
      };
    }, {} as Record<ComfortScore, number>);

  return comfortData;
};

interface ComfortScoreTileProps {
  propertyId: string;
  propertyInfo?: PropertyDetail;
  hasEnviroQs: boolean;
  colSpan?: ResponsiveValue<number | "auto">;
  rowSpan?: ResponsiveValue<number | "auto">;
  isLoading: boolean;
  comfortIndexPercentages?: ComfortIndexPercentages;
  previousComfortIndexPercentages?: ComfortIndexPercentages;
  dateRange: TimestampRange;
  detailsMetricButton?: boolean;
}

export const getUncomfortPercentTextElement = (
  comfortableValue: number,
  comfortIndexPercentages: ComfortIndexPercentages,
  previousComfortableValue?: number
) => {
  const { maxLabel, maxValue } = Object.keys(comfortIndexPercentages || {})
    .filter((key) => key !== "perfectComfort")
    .reduce(
      (acc, key) => {
        const comfortScore = ComfortIndexCategoryMap[key as ComfortIndexCategory];
        const value = comfortIndexPercentages![key as keyof ComfortIndexPercentages] as number;
        if (value > acc.maxValue) {
          return { maxLabel: getComfortScoreDisplayText(comfortScore), maxValue: value };
        }
        return acc;
      },
      { maxLabel: "", maxValue: 0 }
    );

  const maxPercent = Math.floor(maxValue * 100);
  const uncomfortableValue = 1 - comfortableValue;

  const previousUncomfortableValue = 1 - (previousComfortableValue || 0);

  const uncomfortablePercent = gaussianRound(uncomfortableValue * 1000) / 10;

  if (comfortableValue === 1) {
    return (
      <Text>
        <Text fontWeight="bold" display="inline" textAlign="center">
          100%
        </Text>{" "}
        of time spent at a{" "}
        <Text fontWeight="bold" display="inline">
          comfortable
        </Text>{" "}
        level
      </Text>
    );
  }

  return (
    <Flex textAlign="center" justifyContent="center">
      <Text textAlign="center">
        <Text fontWeight="bold" display="inline" textAlign="center">
          {maxPercent}%
        </Text>{" "}
        of time spent in{" "}
        <Text fontWeight="bold" display="inline">
          {maxLabel}
        </Text>{" "}
        conditions &nbsp;
        {previousComfortableValue !== undefined && (
          <Box display="inline" sx={{ "*": { display: "inline-block !important" } }}>
            <PercentageDelta
              inverted
              currentValue={uncomfortableValue * 100}
              previousValue={previousUncomfortableValue * 100}
            />
          </Box>
        )}
      </Text>
    </Flex>
  );
};

export const ComfortScoreTile = ({
  propertyInfo,
  hasEnviroQs,
  colSpan,
  rowSpan,
  isLoading,
  comfortIndexPercentages,
  previousComfortIndexPercentages,
  propertyId,
  dateRange,
  detailsMetricButton,
}: ComfortScoreTileProps) => {
  const comfortableValue = comfortIndexPercentages ? comfortIndexPercentages["perfectComfort"] : 0;

  const previousComfortableValue =
    previousComfortIndexPercentages !== undefined
      ? previousComfortIndexPercentages["perfectComfort"]
      : undefined;

  const comfortSubText = comfortIndexPercentages
    ? getUncomfortPercentTextElement(comfortableValue, comfortIndexPercentages, previousComfortableValue)
    : "";

  return (
    <OverviewTileV2
      reading={""}
      subText={comfortSubText}
      showNoDeviceState={!comfortIndexPercentages && !hasEnviroQs}
      showNoDataState={!comfortIndexPercentages}
      noDeviceStateConfig={NO_DEVICE_STATE}
      noDataStateConfig={NO_DATA_STATE}
      isLoading={isLoading}
      colSpan={colSpan}
      rowSpan={rowSpan}
      heading="Comfort Index"
      toolTipText=""
      propertyId={propertyId}
      detailsMetric={OverviewTileMetric.ComfortIndex}
      dateRange={dateRange}
      detailsMetricButton={detailsMetricButton}
      iconBg={"rgba(68, 153, 255, 0.1)"}
    >
      {comfortIndexPercentages && (
        <ComfortScoreChart comfortIndexPercentages={comfortIndexPercentages} type="radial" />
      )}
    </OverviewTileV2>
  );
};
