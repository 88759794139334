import * as React from "react";

const EnergyCostIndexIcon = ({ fill, size = 16 }: { fill?: string; size?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox={`0 0 16 16`}>
    <path
      d="M7.782 15.183c4.087 0 7.47-3.392 7.47-7.471 0-4.087-3.39-7.47-7.478-7.47-4.08 0-7.463 3.383-7.463 7.47 0 4.08 3.391 7.47 7.47 7.47Zm0-3.157a.346.346 0 0 1-.36-.359v-.542c-1.01-.095-1.852-.652-2.065-1.523a.826.826 0 0 1-.036-.235c0-.263.183-.447.44-.447.226 0 .38.118.468.381.125.498.52.88 1.194.974V8.05l-.051-.015c-1.26-.3-1.912-.871-1.912-1.83 0-1.034.82-1.751 1.963-1.876v-.52c0-.212.154-.359.359-.359s.359.147.359.36v.52c.966.109 1.743.673 1.94 1.486.015.08.037.161.037.234a.43.43 0 0 1-.447.447c-.241 0-.388-.146-.461-.373-.154-.506-.527-.828-1.07-.923v2.102l.103.022c1.311.234 1.978.857 1.978 1.882 0 1.143-.916 1.824-2.08 1.934v.527a.346.346 0 0 1-.36.359Zm-.36-4.915V5.2c-.636.11-1.025.498-1.025.952 0 .447.3.747.975.945l.05.014Zm.719 1.114v2.05c.769-.088 1.142-.49 1.142-1.018 0-.476-.249-.79-.981-.988l-.161-.044Z"
      fill={fill || "#7133C3"}
    />
  </svg>
);

export default EnergyCostIndexIcon;
