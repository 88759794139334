import * as React from "react";

const CarbonIndexIcon = ({ fill, size = 17 }: { fill?: string; size?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size * 0.75} height={size} fill="none" viewBox="0 0 12 17">
    <path
      d="M11.42 11.861c.117.176.205.352.205.528a.691.691 0 0 1-.205.468.686.686 0 0 1-.498.206H7.406c0 .497.088.908.293 1.2.117.264.352.528.703.792.176.146.205.322.147.527-.088.205-.235.293-.44.293H3.891c-.235 0-.381-.088-.44-.293-.088-.205-.03-.38.147-.527.351-.293.586-.557.761-.85.147-.293.235-.674.235-1.143H1.078c-.205 0-.38-.058-.498-.205a.583.583 0 0 1-.205-.468c0-.176.059-.352.205-.528l2.432-2.549h-.996c-.205 0-.381-.058-.498-.205a.583.583 0 0 1-.206-.468c0-.176.06-.352.206-.498L3.92 5.562h-.967c-.205 0-.38-.058-.498-.205a.583.583 0 0 1-.205-.468c0-.176.059-.352.176-.498l3.047-3.282A.734.734 0 0 1 6 .875c.176 0 .352.088.527.234l3.047 3.282a.796.796 0 0 1 .176.498.691.691 0 0 1-.205.468.686.686 0 0 1-.498.205H8.08l2.402 2.579c.118.146.206.322.206.498a.691.691 0 0 1-.206.468.686.686 0 0 1-.498.206h-.996l2.432 2.548Z"
      fill={fill || "#3BD392"}
    />
  </svg>
);

export default CarbonIndexIcon;
