import { Sample } from "../../lib/api/types/Sample";
import { CovidCo2RiskLevel, getCovidRiskLevel } from "../../lib/utils/covid-care";
import { isSampleRecent, isSampleRecentWithCO2 } from "../../lib/utils/isSampleRecent";

export enum SampleCo2RiskLevel {
  LOW = "Low",
  MODERATE = "Mid",
  HIGH = "High",
  OFFLINE = "Offline",
  NO_DEVICE = "NoDevice",
}

export const shadedCovidRiskColors: Record<SampleCo2RiskLevel, string> = {
  [SampleCo2RiskLevel.LOW]: "#EFFFD4",
  [SampleCo2RiskLevel.MODERATE]: "#FFEACD",
  [SampleCo2RiskLevel.HIGH]: "#FFD7DC",
  [SampleCo2RiskLevel.OFFLINE]: "#F3F3F3",
  [SampleCo2RiskLevel.NO_DEVICE]: "#FFFFFF",
};

export const covidRiskColors: Record<SampleCo2RiskLevel, string> = {
  [SampleCo2RiskLevel.LOW]: "#6DC96E",
  [SampleCo2RiskLevel.MODERATE]: "#F2A63D",
  [SampleCo2RiskLevel.HIGH]: "#FB5169",
  [SampleCo2RiskLevel.OFFLINE]: "#595F6A",
  [SampleCo2RiskLevel.NO_DEVICE]: "#595F6A",
};

interface ColorRiskData {
  riskLevel?: CovidCo2RiskLevel;
  shadedColor: string;
  color: string;
}

export const getCovidColorsAndRiskFromSample = (
  hasCO2Sensor: boolean,
  latestSample?: Sample,
  deviceType?: string
): ColorRiskData => {
  const hasReportedRecently = latestSample ? isSampleRecentWithCO2(latestSample, deviceType) : false;

  if (!hasReportedRecently) {
    const type = hasCO2Sensor ? SampleCo2RiskLevel.OFFLINE : SampleCo2RiskLevel.NO_DEVICE;
    return {
      shadedColor: shadedCovidRiskColors[type],
      color: covidRiskColors[type],
    };
  }

  const covidRiskLevel = getCovidRiskLevel(latestSample!.sample!.co2);

  return {
    shadedColor: shadedCovidRiskColors[covidRiskLevel],
    color: covidRiskColors[covidRiskLevel],
    riskLevel: covidRiskLevel,
  };
};

export function getCovidRiskLevelColor(riskLevel: CovidCo2RiskLevel): string {
  return covidRiskColors[riskLevel];
}
