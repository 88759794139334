import { Box } from "@chakra-ui/react";
import { MouldRiskCategory, MouldRiskPercentages } from "../../lib/api/types/Insights";
import LinearPercentageChart from "../linear-percentage-chart/LinearPercentageChart";
import { StatsPieChart } from "../overview-tiles/tiles/StatsPieChart";

export const getMouldRiskColor = (risk: MouldRiskCategory) => {
  switch (risk) {
    case "veryLow":
      return "#6DC96E";
    case "low":
      return "#8DA94E";
    case "mid":
      return "#F2A63D";
    case "high":
      return "#FB7769";
    case "veryHigh":
      return "#FB5169";
  }

  return "gray";
}
export default function ProductivityChart({
  type,
  productivityPercentage,
  size = "md",
}: {
  type: "linear" | "radial",
  productivityPercentage?: number
  size?: "sm" | "md";
}) {

  const graphData =
    [
      { title: "Productive", value: productivityPercentage || 0, color: "#6DC96E" },
      { title: "Unproductive", value: productivityPercentage !== undefined ? 1 - productivityPercentage : 0, color: "#FB5169" },
      { title: "No Data", value: productivityPercentage === undefined ? 1 : 0, color: "#cccccc" }
    ];


  switch (type) {
    case "linear":
      return (
        <LinearPercentageChart sections={graphData.reverse()} />
      );
    case "radial":
    default:
      return <StatsPieChart graphData={graphData} size={size} />;
  }
}
