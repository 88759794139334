import { Text } from "@chakra-ui/react";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { BsHandThumbsUpFill } from "react-icons/bs";

const EnergyUseIntensityExplainText = () => (
  <Text>
    Our Energy Use Intensity (EUI) metric helps you see how energy efficient your building is in comparison to
    other buildings of the same type/functions.
    <br />
    <br />
    Energy Use Intensity (EUI) is calculated using your buildings total energy consumption divided by the
    total square metres or floor area of the building.
    <br />
    <br />
    Generally, a low EUI signifies good energy performance. However, certain property types will always use
    more energy than others.
    <br />
    <br />
    Tracking the EUI can help organisations understand energy cost, track your carbon footprint and uncover
    potential issues that may be causing excessive energy consumption.
    <br />
    <br />
    In addition, EUI is an industry recognised metric for benchmarking energy performance across various
    building types and functions.
    <br />
    <br />
  </Text>
);

const EnergyUseIntensityDetails = ({
  propertyId,
  dateRange,
}: {
  propertyId: string;
  dateRange: TimestampRange;
}) => {
  return (
    <OverviewTileDetailView
      title="Energy Use Intensity"
      titleIcon={<BsHandThumbsUpFill size={16} fill="#EA6BA6" />}
      shortDescription={<EnergyUseIntensityExplainText />}
      propertyId={propertyId}
      roomStatsTitle=""
      dateRange={dateRange}
    />
  );
};

export default EnergyUseIntensityDetails;
