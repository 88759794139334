import { Text } from "@chakra-ui/react";
import OverviewTileDetailView from "./OverviewTileDetailView";
import { TimestampRange } from "../../../lib/api/types/TimestampRange";
import { BsLightningChargeFill } from "react-icons/bs";
import { CircuitNodeWithUsage } from "../../../lib/api/types/CircuitNode";
import numeral from "numeral";

const EnergyUsageExplainText = () => (
  <Text>
    Our Energy Usage metric helps you see the total energy consumption of your building over a given period.
    <br />
    <br />
    The metric also compares the current period of consumption with the previous, allowing you to easily see
    if you’ve saved energy or consumed more.
    <br />
    <br />
    Tracking energy usage can help organisations benchmark a building’s energy consumption and also identify
    operational inefficiencies that may be causing excessive use.
    <br />
    <br />
  </Text>
);

const EnergyUsageDetails = ({ propertyId, dateRange }: { propertyId: string; dateRange: TimestampRange }) => {
  return (
    <OverviewTileDetailView
      title="Energy Usage"
      titleIcon={<BsLightningChargeFill size={16} fill="#F2A63D" />}
      shortDescription={<EnergyUsageExplainText />}
      propertyId={propertyId}
      roomStatsTitle=""
      dateRange={dateRange}
      renderEnergyView={(circuitNode: CircuitNodeWithUsage) => (
        <>
          {circuitNode.circuits.filter((c) => c.device).length > 0 && circuitNode.usage
            ? numeral(circuitNode.usage.totalkWh).format("") + " kWh"
            : "No data"}
        </>
      )}
    />
  );
};

export default EnergyUsageDetails;
