import { Box, HStack, Text, VStack, ListItem, UnorderedList } from "@chakra-ui/layout";
import { Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { TbHeartOff } from "react-icons/tb";
import { useGetMonitoringHours } from "../../lib/api/monitoring-hours/hooks";
import { MonitoringHours } from "../../lib/api/types/MonitoringHours";
import { useIFrameMessaging } from "../../lib/auth/useIFrameMessaging";
import { DefaultModal } from "../modals/DefaultModal";

interface NoDataOverviewTileProps {
  title: string;
  text?: string;
  icon?: JSX.Element;
  customHeight?: string;
}

const isAppliedToWeekends = (monitoringHours: MonitoringHours[] | undefined) => {
  if (!monitoringHours) return false;
  return monitoringHours!.some((d) => d.dayOfWeek === "saturday");
};

const getRange = (monitoringHours: MonitoringHours[] | undefined) => {
  if (monitoringHours) {
    const [monday] = monitoringHours;
    if (monday) {
      return {
        start: monday.startTime,
        end: monday.endTime,
      };
    }
  }
  return {
    start: "00:00",
    end: "23:59",
  };
};

export const NoDataOverviewTile = ({ title, text, icon, customHeight = "100%" }: NoDataOverviewTileProps) => {
  const iFrameMessaging = useIFrameMessaging();

  const [showModal, setShowModal] = useState<boolean>(false);
  const { monitoringHours, isLoading: isLoadingMonitoringHours } = useGetMonitoringHours();
  const appliedToWeekend = isAppliedToWeekends(monitoringHours);
  const range = getRange(monitoringHours);

  const tooltipText =
    !isLoadingMonitoringHours &&
    `${range.start} - ${range.end}, ${appliedToWeekend ? "including" : "excluding"} weekends`;

  return (
    <>
      <Box
        borderRadius={8}
        p={6}
        overflow="hidden"
        bg="#f7f7f7"
        sx={{
          "&:hover": {
            boxShadow: "lg",
          },
        }}
        shadow="lg"
        h={customHeight}
        display="flex"
      >
        <VStack alignItems="stretch" justifyContent="center">
          <HStack>
            {Boolean(icon) && (
              <Box display="flex" justifyContent="center">
                {icon}
              </Box>
            )}
            <Text>{title}</Text>
          </HStack>
          <Box display="flex" alignItems="center">
            <DefaultModal
              isOpen={showModal}
              onClose={() => setShowModal(false)}
              title={`Missing ${title}`}
              hideCancel
            >
              <Text>
                Your {title.toLowerCase()} can be missing for a few different reasons:
                <UnorderedList pl={2} py={2}>
                  <ListItem>
                    You have selected a date range during a period when no devices were installed
                  </ListItem>
                  <ListItem>
                    You are looking at a date range outside of the
                    <Text color="blue">
                      <Tooltip label={tooltipText} hasArrow>
                        occupied hours
                      </Tooltip>
                    </Text>
                  </ListItem>
                  <ListItem>The device is offline</ListItem>
                </UnorderedList>
                {!iFrameMessaging?.isEmbedHost && (
                  <Text>
                    If none of these scenarios are the case, talk to our friendly team on +64 9 870 1801 or
                    email us at support@tetherhq.com and we can help solve the problem.
                  </Text>
                )}
              </Text>
            </DefaultModal>
            <HStack alignItems="flex-start" justifyContent="center">
              <Box>
                <Tooltip
                  label={text || `We can't find any ${title.toLowerCase()} data for your chosen dates.`}
                >
                  <Text size="md" lineHeight="18px" minHeight="30px" mb={1} noOfLines={2}>
                    {text || `We can't find any ${title.toLowerCase()} data for your chosen dates.`}
                  </Text>
                </Tooltip>
                <Text cursor="pointer" onClick={() => setShowModal(true)} size="md" color="blue">
                  {"Why's that?"}
                </Text>
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Box>
    </>
  );
};
