import { Box } from "@chakra-ui/react";
import { MouldRiskCategory, MouldRiskPercentages } from "../../lib/api/types/Insights";
import LinearPercentageChart from "../linear-percentage-chart/LinearPercentageChart";
import { StatsPieChart } from "../overview-tiles/tiles/StatsPieChart";

export const getMouldRiskColor = (risk: MouldRiskCategory) => {
  switch (risk) {
    case "veryLow":
      return "#6DC96E";
    case "low":
      return "#8DA94E";
    case "mid":
      return "#F2A63D";
    case "high":
      return "#FB7769";
    case "veryHigh":
      return "#FB5169";
  }

  return "gray";
};

export default function MouldIndexChart({
  type,
  mouldRiskPercentages,
  size = "md",
}: {
  type: "linear" | "radial";
  mouldRiskPercentages?: MouldRiskPercentages;
  size?: "sm" | "md";
}) {
  const airbornePercentData = [
    {
      title: "Very Low Risk",
      value: mouldRiskPercentages?.veryLow || 0,
      color: getMouldRiskColor(MouldRiskCategory.VeryLow),
    },
    {
      title: "Low Risk",
      value: mouldRiskPercentages?.low || 0,
      color: getMouldRiskColor(MouldRiskCategory.Low),
    },
    {
      title: "Mid Risk",
      value: mouldRiskPercentages?.mid || 0,
      color: getMouldRiskColor(MouldRiskCategory.Mid),
    },
    {
      title: "High Risk",
      value: mouldRiskPercentages?.high || 0,
      color: getMouldRiskColor(MouldRiskCategory.High),
    },
    {
      title: "Very High Risk",
      value: mouldRiskPercentages?.veryHigh || 0,
      color: getMouldRiskColor(MouldRiskCategory.VeryHigh),
    },
  ];

  switch (type) {
    case "linear":
      return <LinearPercentageChart sections={airbornePercentData.reverse()} />;
    case "radial":
    default:
      return <StatsPieChart graphData={airbornePercentData} size={size} />;
  }
}
