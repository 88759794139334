import { isArray } from "lodash";
import { useQuery, UseQueryOptions } from "react-query";
import {
  FullCircuitReading,
  FullPropertyReadings,
  FullPropertyRoomReading,
  POWER_READING,
  PropertyPulseReading,
  PropertyReadingStruct,
  PropertyWaterReading,
  PULSE_METER_READING,
  WATER_READING,
} from "../../../components/property-graph/GraphUtils";

import { DateRangeResolution } from "../../../components/property/DateRangeControls";
import { useAuth } from "../../auth/useAuth";
import { useAggregatedQueries } from "../../hooks/useAggregatedQueries";

import { api } from "../api";
import { QueryKeys } from "../properties/hooks";
import { OrganisationEnergyReadings } from "../types/EnergyInsights";
import { InsightsReadings, RoomLocationInsightsReadings } from "../types/Insights";
import { ReadingSet } from "../types/ReadingSet";
import { RoomLocation } from "../types/RoomLocation";
import { TimestampRange } from "../types/TimestampRange";

export type ReadingResolution =
  | "RAW"
  | "MINUTE"
  | "FIFTEENMINUTE"
  | "THIRTYMINUTE"
  | "HOURLY"
  | "DAILY"
  | "MONTHLY"
  | "TOTAL";

export interface ReadingQueryParams extends TimestampRange {
  resolution: ReadingResolution;
  skipReadings?: "true";
}

export type OccupancyReadingStruct = {
  location: RoomLocation;
  readings: {
    startTimestamp: string;
    endTimestamp: string;
    occupied: number;
  }[];
};

export const useGetRoomOccupancyReadings = (
  roomId: string | undefined,
  queryParams: { fromTimestamp: string; toTimestamp: string },
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: data, ...rest } = useQuery<OccupancyReadingStruct[], Error>(
    [QueryKeys.OCCUPANCY_READINGS, roomId, ...Object.values(queryParams)],
    () =>
      api.get(`experience/v1/readings/room-occupancy/${roomId}`, {
        ...queryParams,
        resolution: "TOTAL",
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(roomId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    readings: data,
    ...rest,
  };
};

export const useGetPropertyPowerReadings = (
  propertyId: string | null,
  queryParams: ReadingQueryParams,
  options?: UseQueryOptions<FullCircuitReading, Error>
) => {
  const { data: data, ...rest } = useQuery<FullCircuitReading, Error>(
    [QueryKeys.PROPERTY_POWER, propertyId, ...Object.values(queryParams)],
    () =>
      api.get(`experience/v1/readings/property-power/${propertyId}`, {
        ...queryParams,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(propertyId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return { propertyPower: data ? data : undefined, ...rest };
};

export const useGetOrganisationPowerReadings = (
  queryParams: ReadingQueryParams,
  options?: UseQueryOptions<OrganisationEnergyReadings, Error>
) => {
  const { user } = useAuth();
  const { data: data, ...rest } = useQuery<OrganisationEnergyReadings, Error>(
    [QueryKeys.PROPERTY_POWER, user?.currentOrganisationId, ...Object.values(queryParams)],
    () =>
      api.get(`experience/v1/readings/organisation-power/${user?.currentOrganisationId}`, {
        ...queryParams,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled:
        Boolean(user?.currentOrganisationId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return { organisationPower: data ? data : undefined, ...rest };
};

export const useGetPropertyWaterReadings = (
  propertyId: string | null,
  queryParams: ReadingQueryParams,
  options?: UseQueryOptions<PropertyWaterReading, Error>
) => {
  const { data: data, ...rest } = useQuery<PropertyWaterReading, Error>(
    [QueryKeys.PROPERTY_WATER, propertyId, ...Object.values(queryParams)],
    () =>
      api.get(`experience/v1/readings/property-water/${propertyId}`, {
        ...queryParams,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(propertyId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return { propertyWater: data ? data : undefined, ...rest };
};

export const useGetPropertyPulseReadings = (
  propertyId: string | null,
  queryParams: ReadingQueryParams,
  options?: UseQueryOptions<PropertyPulseReading, Error>
) => {
  const { data: data, ...rest } = useQuery<PropertyPulseReading, Error>(
    [QueryKeys.PROPERTY_PULSE_READINGS, propertyId, ...Object.values(queryParams)],
    () =>
      api.get(`experience/v1/readings/property-pulse-usage/${propertyId}`, {
        ...queryParams,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(propertyId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return { propertyPulseReadings: data, ...rest };
};

interface PropertyInsightsReadings {
  healthScore: [number, number][];
  mouldRisk: [number, number][];
}

export const useGetPropertyInsightsReadings = (
  propertyId: string | null,
  dateRange: TimestampRange,
  options?: UseQueryOptions<PropertyInsightsReadings, Error>
) => {
  const { data: data, ...rest } = useQuery<PropertyInsightsReadings, Error>(
    [QueryKeys.PROPERTY_INSIGHT_READINGS, propertyId, ...Object.values(dateRange)],
    () =>
      api.get(`/insights/v1/property/${propertyId}/readings`, {
        startDate: dateRange.fromTimestamp,
        endDate: dateRange.toTimestamp,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(propertyId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return { propertyInsightsReadings: data ? data : undefined, ...rest };
};

export const useGetPropertyReadings = (
  propertyId: string | null,
  queryParams: ReadingQueryParams,
  options?: UseQueryOptions<FullPropertyReadings, Error>
) => {
  const { data: propertyReadings, ...rest } = useQuery<FullPropertyReadings, Error>(
    [QueryKeys.PROPERTY_READINGS, propertyId, ...Object.values(queryParams)],
    () =>
      api.get(`/experience/v1/readings/property-readings/${propertyId}`, {
        ...queryParams,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(propertyId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return { propertyReadings, ...rest };
};

type CombinedPropertyReadingsQueryOptions = {
  enabled?: boolean;
};

export type CombinedReadingQueryParams = {
  fromTimestamp: string;
  toTimestamp: string;
  resolution: DateRangeResolution;
  skipReadings?: "true";
};

export const useCombinedPropertyReadings = (
  propertyId: string | null,
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const {
    isLoading: powerLoading,
    propertyPower: powerResult,
    error: powerError,
  } = useGetPropertyPowerReadings(
    propertyId as string,
    { ...queryParams, resolution: queryParams.resolution.powerResolution },
    options
  );

  const {
    isLoading: readingsLoading,
    propertyReadings: readingsResult,
    error: readingsError,
  } = useGetPropertyReadings(
    propertyId as string,
    { ...queryParams, resolution: queryParams.resolution.environmentalResolution },
    options
  );

  const {
    isLoading: insightReadingsLoading,
    readings: insightReadings,
    error: insightReadingsError,
  } = useGetInsightReadingsForMultipleRoomLocations(
    (readingsResult?.roomLocations || []).map((r) => r.id) as string[],
    { ...queryParams, resolution: queryParams.resolution },
    { enabled: readingsResult !== undefined }
  );

  const { propertyPulseReadings, isLoading: propertyPulseReadingsLoading } = useGetPropertyPulseReadings(
    propertyId as string,
    { ...queryParams, resolution: queryParams.resolution.powerResolution },
    options
  );

  const roomReadingsWithInsights = (readingsResult?.roomLocations || []).map((roomLocation) => {
    const insights = insightReadings?.find((r) => r.id === roomLocation.id);
    return {
      ...roomLocation,
      readings: {
        ...roomLocation.readings,
        ...insights?.readings,
      },
    };
  });
  const readings = {
    circuits: powerResult ? powerResult.circuits : [],
    outsides: readingsResult ? readingsResult.outsides : [],
    roomLocations: roomReadingsWithInsights,
    mainsCircuit: powerResult ? powerResult.mainsCircuit : null,
    overallCircuitInsights: powerResult?.insights,
    overallRoomInsights: readingsResult?.insights,
    waterOutsides: propertyPulseReadings
      ? propertyPulseReadings.outsides.filter((o) => o.waterUsage.length > 0)
      : [],
    gasOutsides: propertyPulseReadings
      ? propertyPulseReadings.outsides.filter((o) => o.gasUsage.length > 0)
      : [],
    pulseInsights: propertyPulseReadings ? propertyPulseReadings.insights : null,
  };

  return {
    allLoading: powerLoading || readingsLoading || propertyPulseReadingsLoading || insightReadingsLoading,
    powerLoading,
    readingsLoading,
    pulseReadingsLoading: propertyPulseReadingsLoading,
    insightReadingsLoading,
    combinedReadings: readings,
    error: readingsError || powerError || insightReadingsError,
  };
};

export const useGetRoomLocationReadings = (
  roomLocationId: string | null,
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: data, ...rest } = useQuery<PropertyReadingStruct[], Error>(
    [QueryKeys.ROOM_LOCATION_READINGS, roomLocationId, ...Object.values(queryParams)],
    () =>
      api.get(`property/v2/roomlocation/${roomLocationId}/readings`, {
        ...queryParams,
        resolution: queryParams.resolution.environmentalResolution,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(roomLocationId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    readings: data,
    ...rest,
  };
};

export const useGetReadingsForMultipleRoomLocations = (
  roomLocationIds: string[],
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: readings, errors, isLoading } = useAggregatedQueries(
    roomLocationIds.map((id) => ({
      queryKey: [QueryKeys.ROOM_LOCATION_READINGS, id, ...Object.values(queryParams)],
      queryFn: () =>
        api.get(`property/v2/roomlocation/${id}/readings`, {
          ...queryParams,
          resolution: queryParams.resolution.environmentalResolution,
          milliseconds: "true",
        }),
      select: (readings: any) => ({ id, readings }),
      cacheTime: 600000,
      ...options,
    }))
  );

  return { readings, error: errors.find((e) => e !== undefined), isLoading };
};

export const useGetInsightReadingsForMultipleRoomLocations = (
  roomLocationIds: string[],
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: readings, errors, isLoading } = useAggregatedQueries<
    InsightsReadings,
    Error,
    RoomLocationInsightsReadings
  >(
    roomLocationIds.map((id) => ({
      queryKey: [QueryKeys.ROOM_LOCATION_READINGS_INSIGHTS, id, ...Object.values(queryParams)],
      queryFn: () =>
        api.get(`insights/v1/roomlocation/${id}/readings`, {
          startDate: queryParams.fromTimestamp,
          endDate: queryParams.toTimestamp,
          // milliseconds: "true", <-- add this when its supported
        }),
      select: (readings) => ({ id, readings }),
      cacheTime: 600000,
      ...options,
    }))
  );

  // TODO: remove when milliseconds=true is supported
  const formattedReadings = readings
    .filter((r) => r)
    .map((r) => {
      const newReadings: InsightsReadings = {};
      // exclude insights that aren't just an array for now.
      // This will include stuff like airborne index, low, med high etc.
      Object.keys(r.readings)
        .filter((k) => Array.isArray(r.readings[k as keyof InsightsReadings]))
        .map((key) => {
          const existing = r.readings[key as keyof InsightsReadings];
          newReadings[key as keyof InsightsReadings] = (isArray(existing)
            ? existing.map((dataValue: [number, number]) => [
                dataValue[0] * 1000,
                dataValue[1],
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ])
            : undefined) as any;
        });
      return {
        id: r.id,
        readings: newReadings,
      };
    });

  return { readings: formattedReadings, error: errors.find((e) => e !== undefined), isLoading };
};

export const useGetReadingsForMultipleCircuits = (
  circuitIds: string[],
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: readings, errors, isLoading } = useAggregatedQueries(
    circuitIds.map((id) => ({
      queryKey: [QueryKeys.CIRCUIT_READINGS, id, ...Object.values(queryParams)],
      queryFn: () =>
        api.get(`property/v2/circuit/${id}/power`, {
          ...queryParams,
          resolution: queryParams.resolution.powerResolution,
          milliseconds: "true",
        }),
      select: (readings: any) => ({ id, readings }),
      cacheTime: 600000,
      ...options,
    }))
  );

  return { readings, error: errors.find((e) => e !== undefined), isLoading };
};

export const useGetOutsideReadings = (
  outsideId: string | null,
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: data, ...rest } = useQuery<ReadingSet, Error>(
    [QueryKeys.OUTSIDE_READINGS, outsideId, ...Object.values(queryParams)],
    () =>
      api.get(`property/v2/outside/${outsideId}/readings`, {
        ...queryParams,
        resolution: queryParams.resolution.environmentalResolution,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(outsideId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    readings: data,
    ...rest,
  };
};

export const useGetWaterForOutside = (
  outsideId: string | null,
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: data, ...rest } = useQuery<WATER_READING[], Error>(
    [QueryKeys.OUTSIDE_WATER, outsideId, ...Object.values(queryParams)],
    () =>
      api.get(`property/v2/outside/${outsideId}/waterUsage`, {
        ...queryParams,
        resolution: queryParams.resolution.environmentalResolution,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(outsideId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    readings: data,
    ...rest,
  };
};

export const useGetPulseReadingsForOutside = (
  outsideId: string | null,
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: data, ...rest } = useQuery<PULSE_METER_READING, Error>(
    [QueryKeys.OUTSIDE_PULSE_METER, outsideId, ...Object.values(queryParams)],
    () =>
      api.get(`property/v2/outside/${outsideId}/pulse-meter-usage`, {
        ...queryParams,
        resolution: queryParams.resolution.environmentalResolution,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(outsideId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    readings: data,
    ...rest,
  };
};

export const useGetCircuitReadings = (
  circuitId: string | null,
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: data, ...rest } = useQuery<POWER_READING[], Error>(
    [QueryKeys.CIRCUIT_READINGS, circuitId, ...Object.values(queryParams)],
    () =>
      api.get(`property/v2/circuit/${circuitId}/power`, {
        ...queryParams,
        resolution: queryParams.resolution.powerResolution,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(circuitId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    readings: data,
    ...rest,
  };
};

export const useGetRoomReadings = (
  roomId: string | null,
  queryParams: CombinedReadingQueryParams,
  options?: CombinedPropertyReadingsQueryOptions
) => {
  const { data: data, ...rest } = useQuery<{ roomLocations: FullPropertyRoomReading[] }, Error>(
    [QueryKeys.ROOM_READINGS, roomId, ...Object.values(queryParams)],
    () =>
      api.get(`experience/v1/readings/room-readings/${roomId}`, {
        ...queryParams,
        resolution: queryParams.resolution.environmentalResolution,
        milliseconds: "true",
      }),
    {
      staleTime: 600000,
      ...options,
      enabled: Boolean(roomId) && (options?.enabled === undefined ? true : options.enabled),
    }
  );

  return {
    readings: data,
    ...rest,
  };
};
